import { SendOutlined, WarningTwoTone } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Col,
  Collapse,
  Form,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLoanCalculatorOperationResult,
  getLoanCalculatorPaymentDetails,
  listLoanCalculatorAgencies,
} from "../../store/loanCalculator/loanCalculatorActions";
import CustomDatePicker from "../shared/form/reactHookForm/CustomDatePicker";
import CustomInput from "../shared/form/reactHookForm/CustomInput";
import CustomInputCurrency from "../shared/form/reactHookForm/CustomInputCurrency";
import CustomInputNumber from "../shared/form/reactHookForm/CustomInputNumber";
import CustomRadioGroup from "../shared/form/reactHookForm/CustomRadioGroup";
import CustomSelect from "../shared/form/reactHookForm/CustomSelect";
import { dateFormat, toStartOfDay } from "../shared/formatting";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import {
  downpaymentOptions,
  loanCalculationMethod,
  loanTypeOptions,
  loanTypes,
} from "./constants";
import { loanInformationPanelSchema } from "./loanCalculatorSchemas";

const layout1 = {
  labelCol: { span: 10, sm: 10, md: 8, lg: 12, xl: 10, xxl: 8 },
  wrapperCol: { span: 14, sm: 14, md: 14, lg: 12, xl: 14, xxl: 16 },
};

const layout2 = {
  labelCol: { span: 10, sm: 10, md: 8, lg: 12, xl: 12, xxl: 9 },
  wrapperCol: { span: 14, sm: 14, md: 14, lg: 12, xl: 12, xxl: 15 },
};

const initialValues = {
  ContractEffectiveDate: toStartOfDay(dayjs()),
  FirstPaymentDate: toStartOfDay(
    dayjs().startOf("month").add(1, "month").add(15, "days")
  ),
  LoanType: loanTypes.NEW,
  LoanPeriod: 10,
  MonthsPaidDown: 2,
  DownPaymentAmount: 0,
  TotalPremiumAmount: null,
  ProcessingCharge: 25,
  InterestRate: 7.5,
  PolicyFees: 0,
  DownpaymentType: "M",
  IsOldMethod: true,
};

const LoanInformationPanel = () => {
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { isValid, isDirty, errors, isSubmitted },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: initialValues,
    resolver: yupResolver(loanInformationPanelSchema),
  });

  //
  const header = useSelector(
    (state) => state.loanCalculator.paymentDetails?.header
  );

  const shouldShowWarning = isDirty && header && header != [];

  const collapseItems = [
    {
      key: "1",
      label: (
        <Space>
          <Typography.Text>Enter Loan Information</Typography.Text>
          {shouldShowWarning && (
            <Typography.Text type="warning">
              <WarningTwoTone twoToneColor="#faad14" /> Selected parameters have
              changed. Press{" "}
              <Typography.Text type="warning" strong>
                "Calculate Loan"
              </Typography.Text>{" "}
              before printing any report.
            </Typography.Text>
          )}
        </Space>
      ),
      children: <LoanInformationPanelBody />,
      collapsible: false,
    },
  ];
  return (
    <FormProvider
      handleSubmit={handleSubmit}
      reset={reset}
      control={control}
      getValues={getValues}
      watch={watch}
      setValue={setValue}
      trigger={trigger}
      isValid={isValid}
      isDirty={isDirty}
      errors={errors}
      isSubmitted={isSubmitted}
    >
      <div>
        <Collapse defaultActiveKey={"1"} items={collapseItems} />
        {shouldShowWarning && (
          <Row style={{ marginTop: 16 }}>
            <Col span={24}>
              <Alert
                message="Loan Information Panel has been modified. Please press 'Calculate Loan' to update the tabs below!"
                type="warning"
                showIcon
              />
            </Col>
          </Row>
        )}
      </div>
    </FormProvider>
  );
};

const LoanInformationPanelBody = () => {
  //
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    watch,
    setValue,
    isValid,
    isSubmitted,
    trigger,
  } = useFormContext();

  const loanType = watch("LoanType");
  const downpaymentType = watch("DownpaymentType");
  const contractEffectiveDate = watch("ContractEffectiveDate");
  const firstPaymentDate = watch("FirstPaymentDate");
  const showFirstPaymentDateWarning =
    loanType === loanTypes.NEW &&
    contractEffectiveDate &&
    firstPaymentDate &&
    (dayjs(firstPaymentDate).date() == 2 ||
      dayjs(firstPaymentDate).date() == 16) &&
    dayjs(firstPaymentDate).isSame(dayjs(contractEffectiveDate), "month");

  //
  const dispatch = useDispatch();

  //
  const agencies = useSelector((state) => state.loanCalculator.agencies.data);
  const agenciesLoading = useSelector(
    (state) => state.loanCalculator.agencies.loading
  );
  const operationResult = useSelector(
    (state) => state.loanCalculator.operationResult
  );
  const loading = useSelector(
    (state) => state.loanCalculator.paymentDetails.loading
  );
  const homeAgencyName = useSelector(
    (state) => state.actor.details.data.AgencyFullName
  );

  const agencyOptions = useMemo(
    () =>
      agencies
        ? agencies.map((a) => {
            return { label: a.AgencyName, value: a.Id };
          })
        : [],
    [agencies]
  );

  //
  useEffect(() => {
    dispatch(listLoanCalculatorAgencies());
  }, []);

  useEffect(() => {
    if (agencyOptions && !isEmpty(agencyOptions)) {
      var newProcessingBranch = agencyOptions[0].value;
      if (homeAgencyName) {
        var parts = homeAgencyName.split(" - ");
        var newName = "JM " + (parts[1] + " " ?? "") + (parts[2] ?? "");
        newName = newName
          .replace("No. ", "#")
          .replace("PG", "PRINCE GEORGE")
          .replace("Dewdney", "HEAD OFFICE")
          .trim()
          .toUpperCase();
        var agencyFound = agencyOptions.find(
          (v) => v.label.toUpperCase() == newName
        );
        if (agencyFound) {
          newProcessingBranch = agencyFound.value;
        } else {
          agencyFound = agencyOptions.find((v) =>
            v.label.toUpperCase().includes(newName)
          );
          if (agencyFound) {
            newProcessingBranch = agencyFound.value;
          }
        }
      }
      reset(
        { ...initialValues, ProcessingBranch: newProcessingBranch },
        { keepDirty: false }
      );
    }
  }, [agencyOptions]);

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearLoanCalculatorOperationResult())
    );
  }, [operationResult]);

  useEffect(() => {
    if (!isValid && isSubmitted) {
      trigger();
    }
  }, [loanType]);

  const onSubmitHandler = (values) => {
    dispatch(
      getLoanCalculatorPaymentDetails({
        ...values,
        ContractEffectiveDate: values.ContractEffectiveDate,
        FirstPaymentDate: values.FirstPaymentDate,
        DownPaymentAmount:
          values.DownpaymentType == "M" ? 0 : values.DownPaymentAmount ?? 0,
        MonthsPaidDown:
          values.DownpaymentType == "M" ? values.MonthsPaidDown ?? 0 : 0,
        ProcessingCharge:
          values.LoanType === loanTypes.NEW ? values.ProcessingCharge : 0,
        CalculationMethod:
          values.LoanType === loanTypes.CHANGE && values.IsOldMethod === true
            ? loanCalculationMethod.DECLINING
            : loanCalculationMethod.FLAT,
      })
    );
    reset({ ...values }, { keepDirty: false });
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          size="small"
          autoComplete="off"
          onFinish={() => handleSubmit(onSubmitHandler)()}
        >
          <Row>
            <Col lg={12} xl={10} xxl={10} xs={24} sm={24} md={24}>
              <CustomRadioGroup
                control={control}
                layout={layout1}
                formLabel="Loan Type"
                name="LoanType"
                required={true}
              >
                {loanTypeOptions}
              </CustomRadioGroup>
              <CustomSelect
                layout={layout1}
                placeholder="Select a branch"
                formLabel="Processing Branch"
                control={control}
                name="ProcessingBranch"
                options={agencyOptions}
                loading={agenciesLoading}
                required={true}
                showSearch={true}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.label
                    .toLowerCase()
                    .localeCompare(optionB.label.toLowerCase())
                }
              />
              <CustomInput
                layout={layout1}
                control={control}
                hasFeedback={true}
                formLabel="Client Name"
                name="ClientName"
              />
              <CustomInput
                control={control}
                layout={layout1}
                hasFeedback={true}
                formLabel="Loan Description"
                name="LoanDescription"
              />
              <CustomInput
                control={control}
                layout={layout1}
                hasFeedback={true}
                formLabel="Representative's Name"
                name="RepresentativeName"
              />
              <CustomInput
                control={control}
                layout={layout1}
                hasFeedback={true}
                formLabel="Producer's Name"
                name="ProducerName"
              />
            </Col>
            <Col lg={12} xl={14} xxl={14} xs={24} sm={24} md={24}>
              {loanType === loanTypes.CHANGE && (
                <div className="remove-colon">
                  <CustomRadioGroup
                    required={true}
                    control={control}
                    name="IsOldMethod"
                    formLabel={
                      "Is this loan based on old method of interest calculation?"
                    }
                  >
                    <Space direction="vertical">
                      <Radio value={true} key="Y">
                        Yes
                      </Radio>
                      <Radio value={false} key="N">
                        No
                      </Radio>
                    </Space>
                  </CustomRadioGroup>
                </div>
              )}

              <CustomDatePicker
                formItem={{
                  required: true,
                  label: "Contract Effective Date",
                  layout: layout2,
                }}
                control={control}
                name="ContractEffectiveDate"
                format={dateFormat}
                timeOfDay="start"
                onBlur={() => {
                  trigger("ContractEffectiveDate");
                }}
                onChange={(date) => {
                  if (date && getValues().LoanType === loanTypes.NEW) {
                    var fpDate = dayjs(
                      getValues().FirstPaymentDate ?? ""
                    ).date();
                    fpDate = fpDate !== 2 ? 16 : 2;
                    var newDay = dayjs(date).set("date", fpDate);
                    setValue(
                      "FirstPaymentDate",
                      toStartOfDay(newDay.add(1, "month")),
                      {
                        shouldValidate: true,
                      }
                    );
                  }
                }}
              />

              <CustomDatePicker
                formItem={{
                  required: true,
                  label: "First Payment Date",
                  layout: layout2,
                  hasFeedback: showFirstPaymentDateWarning,
                  showWarning: showFirstPaymentDateWarning,
                  warning:
                    "First Payment Date should be in the month following the Effective Date",
                }}
                control={control}
                name="FirstPaymentDate"
                format={dateFormat}
                timeOfDay="start"
                onBlur={() => {
                  trigger("FirstPaymentDate");
                }}
              />

              {loanType === loanTypes.NEW && (
                <CustomInputCurrency
                  layout={layout2}
                  formLabel={"Total Premium Amount"}
                  control={control}
                  isPrecision={true}
                  required={true}
                  name="TotalPremiumAmount"
                  afterText={
                    <Typography.Text
                      mark
                      italic
                      style={{ fontSize: 11, paddingLeft: 12 }}
                    >
                      Enter total premium, not amount financed
                    </Typography.Text>
                  }
                />
              )}

              {(loanType === loanTypes.RENEWAL ||
                loanType === loanTypes.CHANGE) && (
                <CustomInputCurrency
                  layout={layout2}
                  formLabel={"Amount Financed"}
                  control={control}
                  isPrecision={true}
                  required={true}
                  name="TotalPremiumAmount"
                />
              )}

              <CustomInputNumber
                formLabel="Finance Rate %"
                control={control}
                name="InterestRate"
                id="InterestRateInput"
                required={true}
                layout={layout2}
                afterChange={(v) => {
                  if (v == null) {
                    setValue("InterestRate", 0, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
              {loanType === loanTypes.NEW && (
                <CustomInputCurrency
                  layout={layout2}
                  formLabel="Processing Charge"
                  control={control}
                  required={true}
                  isPrecision={true}
                  name="ProcessingCharge"
                  afterChange={(v) => {
                    if (v == null) {
                      setValue("ProcessingCharge", 0, {
                        shouldValidate: true,
                      });
                    }
                  }}
                />
              )}
              <CustomInputCurrency
                layout={layout2}
                formLabel="Policy Fees"
                control={control}
                isPrecision={true}
                required={true}
                name="PolicyFees"
                afterChange={(v) => {
                  if (v == null) {
                    setValue("PolicyFees", 0, {
                      shouldValidate: true,
                    });
                  }
                }}
              />
              <CustomSelect
                layout={layout2}
                placeholder="Choose"
                style={{ width: 90 }}
                formLabel="Loan Period (1-100 Months)"
                control={control}
                name="LoanPeriod"
                options={Array.from({ length: 100 }, (_, i) => ({
                  value: i + 1,
                  label: (i + 1).toString(),
                }))}
                required={true}
                showSearch={true}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                afterChange={(v) => {
                  if (loanType === loanTypes.NEW && v != null) {
                    var LoanPeriod = v;
                    var MonthsPaidDown = Math.max(12 - LoanPeriod, 0);
                    var DownPaymentAmount = getValues().DownPaymentAmount;

                    if (
                      DownPaymentAmount == null &&
                      MonthsPaidDown < LoanPeriod
                    )
                      setValue("MonthsPaidDown", MonthsPaidDown, {
                        shouldValidate: true,
                      });
                  }
                }}
              />
              {loanType === loanTypes.NEW && (
                <Form.Item
                  label="Downpayment"
                  required="true"
                  name="DownpaymentLabel"
                  {...layout2}
                >
                  <Row>
                    <Col>
                      <CustomRadioGroup
                        control={control}
                        name="DownpaymentType"
                      >
                        <Space direction="vertical">{downpaymentOptions}</Space>
                      </CustomRadioGroup>
                    </Col>
                    <Col>
                      {downpaymentType == "M" ? (
                        <CustomSelect
                          style={{ width: 100, height: 26 }}
                          control={control}
                          name="MonthsPaidDown"
                          options={Array.from({ length: 100 }, (_, i) => ({
                            value: i,
                            label: i.toString(),
                          }))}
                          showSearch={true}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        />
                      ) : (
                        <CustomInputCurrency
                          style={{ width: 100 }}
                          control={control}
                          isPrecision={true}
                          required={true}
                          name="DownPaymentAmount"
                          afterChange={(v) => {
                            if (v == null) {
                              setValue("DownPaymentAmount", 0, {
                                shouldValidate: true,
                              });
                            }
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Space align="end">
                {/* <Col style={{ paddingRight: "8px" }}>
                  <Button
                    size="small"
                    type="dashed"
                    disabled={
                      loading || !agencyOptions || agencyOptions.length < 1
                    }
                    onClick={() => {
                      reset({
                        ...initialValues,
                        ProcessingBranch: agencyOptions[0].value,
                      });
                      trigger();
                    }}
                  >
                    <UndoOutlined />
                    Reset Values
                  </Button>
                </Col> */}
                <Button
                  disabled={(!isValid && isSubmitted) || loading}
                  autoFocus={true}
                  id="processButton"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  <SendOutlined />
                  Calculate Loan
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};
export default LoanInformationPanel;
