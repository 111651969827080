import { combineReducers } from "@reduxjs/toolkit";
import { reducer as actorReducer } from "./actor/actorReducer";
import { reducer as agencyReducer } from "./agency/agencyReducer";
import { reducer as batchingReducer } from "./batching/batchingReducer";
import { reducer as documentReducer } from "./document/documentReducer";
import { reducer as inventoryReducer } from "./inventory/inventoryReducer";
import { reducer as producerReducer } from "./producer/producerReducer";
import { reducer as rateClassReducer } from "./rateClass/rateClassReducer";
import { reducer as reportReducer } from "./report/reportReducer";
import { reducer as retentionReducer } from "./retention/retentionReducer";
import { reducer as territoryReducer } from "./territory/territoryReducer";
import { reducer as uiReducer } from "./ui/uiReducer";
import { reducer as userReducer } from "./user/general/userReducer";
import { reducer as securityReducer } from "./user/security/securityReducer";
import { reducer as vehicleReducer } from "./vehicle/vehicleReducer";
import { reducer as commissionReducer } from "./commission/commissionReducer";
import { reducer as dataDownloadReducer } from "./dataDownload/dataDownloadReducer";
import { reducer as onlineRenewalsReducer } from "./onlineRenewals/onlineRenewalsReducer";
import { reducer as announcementReducer } from "./announcement/announcementReducer";
import { reducer as manageRenewalsReducer } from "./manageRenewals/manageRenewalsReducer";
import { reducer as dashboardReducer } from "./dashboard/dashboardReducer";
import { reducer as appLogReducer } from "./appLog/appLogReducer";
import { reducer as fleetPolicyCustomMappingReducer } from "./fleetPolicyCustomMappping/fleetPolicyCustomMappingReducer";
import { reducer as moveDocumentsReducer } from "./moveDocuments/moveDocumentsReducer";
import { reducer as loanCalculatorReducer } from "./loanCalculator/loanCalculatorReducer";

function createRootReducer() {
  const appReducer = combineReducers({
    actor: combineReducers({ details: actorReducer }),
    appLog: appLogReducer,
    document: documentReducer,
    user: combineReducers({ general: userReducer, security: securityReducer }),
    agency: agencyReducer,
    vehicle: vehicleReducer,
    rateClass: rateClassReducer,
    retention: retentionReducer,
    territory: territoryReducer,
    batching: batchingReducer,
    inventory: inventoryReducer,
    producer: producerReducer,
    report: reportReducer,
    ui: uiReducer,
    commission: commissionReducer,
    dataDownload: dataDownloadReducer,
    onlineRenewals: onlineRenewalsReducer,
    announcement: announcementReducer,
    manageRenewals: manageRenewalsReducer,
    dashboard: dashboardReducer,
    fleetPolicyCustomMap: fleetPolicyCustomMappingReducer,
    moveDocuments: moveDocumentsReducer,
    loanCalculator: loanCalculatorReducer,
  });

  const rootReducer = (state, action) => {
    if (action.type === "CLEAR_REDUX_STORE") {
      return appReducer(undefined, action);
    }

    if (action.type === "CLEAR_REDUX_STORE_EXCEPT_ACTOR") {
      // Retain the actor state, reset everything else
      const { actor } = state; // Destructure to keep the actor state
      return appReducer(
        {
          actor: {
            details: {
              ...actor?.details,
              switchSupportAccount: { operationResult: null, loading: false },
            },
          },
        },
        action
      ); // Pass the current actor state to the new state
    }

    return appReducer(state, action);
  };

  return rootReducer;
}
export default createRootReducer;
