import {
  EditOutlined,
  FileExcelOutlined,
  FileSyncOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Empty,
  Form,
  Row,
  Space,
} from "antd";
import dayjs from "dayjs";
import { debounce, isEmpty, isNull } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSelectedManageRenewalsRows,
  listManageRenewals,
  setSelectedManageRenewalsRows,
} from "../../../store/manageRenewals/manageRenewalsActions";
import { setTablePreserveOnReload } from "../../../store/ui/uiActions";
import { exportDataToExcel } from "../../shared/exportToExcel";
import AgencyMultiSelectEx from "../../shared/form/AgencyMultiSelectEx";
import DynamicProducerSelect from "../../shared/form/DynamicProducerSelect";
import { fixedDateRanges } from "../../shared/form/fixedDateRanges";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import AdvancedRangePicker from "../../shared/form/reactHookForm/AdvancedRangePicker";
import { dateFormat, toTZNeutralFormat } from "../../shared/formatting";
import { agenciesListEqual } from "../../shared/sorting";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { tableNames } from "../../shared/table/tableNames";
import ManageRenewalsBulkUpdateForm from "./ManageRenewalsBulkUpdateForm";
import { manageRenewalsColumns } from "./manageRenewalsColumns";
import { manageRenewalsSchema } from "./manageRenewalsSchemas";

//
const initialValues = {
  AgencyKeys: [],
  FixedDateRange: fixedDateRanges.NEXT_MONTH,
  ExpiryDate: ["", ""],
  ShowAutoplanPolicies: true,
  ShowSpecialPolicies: true,
  PolicyStatusActive: true,
  PolicyStatusExpired: false,
  PolicyStatusCancelled: false,
  PolicyStatusRenewed: false,
  ProducerCode1Keys: [],
  ProducerCode2Keys: [],
};

const colSize = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 12,
  xxl: 12,
};

const firstLayout = {
  labelCol: {
    xs: null,
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 5 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: null,
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 19 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const secondLayout = {
  labelCol: {
    xs: null,
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 5 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: null,
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 19 },
    xl: { span: 16 },
    xxl: { span: 17 },
  },
};

const ManageRenewalsForm = ({ agencies }) => {
  // Form
  const {
    reset,
    control,
    setValue,
    trigger,
    getValues,
    formState: { defaultValues, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(manageRenewalsSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [viewDetails, setViewDetails] = useState(false);
  const preserveSelectedKeys = useRef(false);
  const updateProducerCodes = useRef(true);

  //
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const loading = useSelector((state) => state.manageRenewals.loading);
  const list = useSelector((state) => state.manageRenewals.data);
  const producerCodes1List = useSelector(
    (state) => state.manageRenewals.producerCodes1List
  );
  const producerCodes2List = useSelector(
    (state) => state.manageRenewals.producerCodes2List
  );
  const producerCodes1Loading = useSelector(
    (state) => state.manageRenewals.producerCodes1Loading
  );
  const producerCodes2Loading = useSelector(
    (state) => state.manageRenewals.producerCodes2Loading
  );
  const selectedRows = useSelector(
    (state) => state.manageRenewals.selectedRows
  );

  //
  const refreshData = (values = getValues(), preserveKeys = false) => {
    if (preserveKeys === true) {
      preserveSelectedKeys.current = true;
    }

    dispatch &&
      dispatch(listManageRenewals(values, updateProducerCodes.current));
    updateProducerCodes.current = false;
  };

  const debouncedRefreshData = useMemo(() => debounce(refreshData, 1000), []);

  //
  useEffect(() => {
    return () => {
      debouncedRefreshData.cancel();
    };
  }, []);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      resetFieldsToDefault();
    }
  }, [agencies, homeAgency]);

  useEffect(() => {
    if (list != null) {
      if (preserveSelectedKeys.current === true) {
        preserveSelectedKeys.current = false;
      } else {
        dispatch(clearSelectedManageRenewalsRows());
      }
    }
  }, [list]);

  //
  const resetFieldsToDefault = () => {
    var initialAgency = getInitialAgency(homeAgency, agencies);
    var newValues = {
      ...initialValues,
      AgencyKeys: [initialAgency],
      FixedDateRange: fixedDateRanges.NEXT_MONTH,
      ExpiryDate: [
        toTZNeutralFormat(dayjs().add(1, "month").startOf("month")),
        toTZNeutralFormat(dayjs().add(1, "month").endOf("month")),
      ],
    };
    reset(newValues);
    trigger();
    debouncedRefreshData.cancel();
    refreshData(newValues);
  };

  const viewDetailsOnCloseHandler = (shouldReload) => {
    setViewDetails(false);
    if (shouldReload === true) {
      dispatch(setTablePreserveOnReload(tableNames.ManageRenewals, true));
      updateProducerCodes.current = true;
      debouncedRefreshData.cancel();
      refreshData(getValues(), true);
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    dispatch(setSelectedManageRenewalsRows(selectedRowKeys));
  };

  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableNames.ManageRenewals)
  );

  const tableCurrentDataSource = tableData?.extra?.currentDataSource;

  useEffect(() => {
    //if a filter is applied when multiple rows selected, set selectedRows only for the records visible on ui
    let currentDataSourceSelectedRowKeys = [];
    if (
      !isNull(tableCurrentDataSource) &&
      !isEmpty(tableCurrentDataSource) &&
      list?.length !== tableCurrentDataSource?.length
    ) {
      const currentDataSourceRowKeys = tableCurrentDataSource.map((r) => r.Id);
      currentDataSourceSelectedRowKeys = selectedRows.filter((r) =>
        currentDataSourceRowKeys.includes(r)
      );
    }
    dispatch(setSelectedManageRenewalsRows(currentDataSourceSelectedRowKeys));
  }, [tableCurrentDataSource, list]);

  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  const [isExporting, setIsExporting] = useState(false);

  const exportTableData = () => {
    setIsExporting(true);
    exportDataToExcel(
      tableCurrentDataSource,
      [
        "Id",
        "RenewalKey",
        "AgencyNumber",
        "AgencyName",
        "AgencyKey",
        "ProducerCode1Key",
        "ProducerCode2Key",
      ],
      maskSensitiveData,
      "RenewalRecords.csv",
      [],
      ["PolicyEffectiveDate", "PolicyExpiryDate"]
    );
    setIsExporting(false);
  };

  return (
    <div>
      <Drawer
        title={
          <>
            <FileSyncOutlined />
            {` Bulk Update ${selectedRows.length.toString()} ${
              selectedRows.length === 1 ? "Renewal" : "Renewals"
            }`}
          </>
        }
        width={400}
        onClose={() => viewDetailsOnCloseHandler(false)}
        open={viewDetails}
        styles={{ body: { paddingTop: 4 } }}
        destroyOnClose={true}
      >
        <ManageRenewalsBulkUpdateForm
          onCloseHandler={viewDetailsOnCloseHandler}
        />
      </Drawer>
      <Form
        size="small"
        autoComplete="off"
        onFinish={() => {}}
        style={{ marginTop: "16px" }}
      >
        <Row>
          <Col {...colSize}>
            <AgencyMultiSelectEx
              layout={firstLayout}
              formLabel="Agency"
              required={true}
              agencies={currentAgencies}
              isLoading={isEmpty(defaultValues.AgencyKeys)}
              setValue={setValue}
              control={control}
              name="AgencyKeys"
              onChange={(newAgencyKeys) => {
                if (newAgencyKeys != null && newAgencyKeys.length > 0) {
                  updateProducerCodes.current = true;
                  debouncedRefreshData();
                }
              }}
            />
          </Col>
          <Col {...colSize}>
            <AdvancedRangePicker
              formItem={{ layout: firstLayout, required: true, label: "Date" }}
              control={control}
              setValue={setValue}
              allowClear={false}
              name="ExpiryDate"
              format={dateFormat}
              afterChange={() => {
                updateProducerCodes.current = true;
                debouncedRefreshData();
              }}
            />
          </Col>

          <Col {...colSize}>
            <Form.Item
              {...firstLayout}
              label="Policy Type"
              name="PolicyType"
              required
              valuePropName="checked"
            >
              <Space direction="vertical">
                <Controller
                  control={control}
                  name="ShowAutoplanPolicies"
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => {
                        const currentValues = getValues();
                        const autoplanPolicies = e.target.checked;
                        const specialPolicies =
                          !currentValues.ShowSpecialPolicies &&
                          !e.target.checked
                            ? true
                            : currentValues.ShowSpecialPolicies;

                        setValue("ShowAutoplanPolicies", autoplanPolicies, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        if (
                          currentValues.ShowSpecialPolicies != specialPolicies
                        ) {
                          setValue("ShowSpecialPolicies", specialPolicies, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }

                        debouncedRefreshData();
                      }}
                      inputRef={field.ref}
                      name="ShowAutoplanPolicies"
                    >
                      Autoplan Policies
                    </Checkbox>
                  )}
                />
                <Controller
                  control={control}
                  name="ShowSpecialPolicies"
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      name="ShowSpecialPolicies"
                      onChange={(e) => {
                        const currentValues = getValues();
                        const specialPolicies = e.target.checked;
                        const autoplanPolicies =
                          !currentValues.ShowAutoplanPolicies &&
                          !e.target.checked
                            ? true
                            : currentValues.ShowAutoplanPolicies;

                        setValue("ShowSpecialPolicies", specialPolicies, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        if (
                          currentValues.ShowAutoplanPolicies != autoplanPolicies
                        ) {
                          setValue("ShowAutoplanPolicies", autoplanPolicies, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }

                        debouncedRefreshData();
                      }}
                      inputRef={field.ref}
                    >
                      Special Coverage Policies
                    </Checkbox>
                  )}
                />
              </Space>
            </Form.Item>
          </Col>
          <Col {...colSize}>
            <Form.Item
              {...firstLayout}
              label="Policy Status"
              name="PolicyStatus"
              required
              valuePropName="checked"
            >
              <Space direction="horizontal">
                <Space direction="vertical">
                  <Controller
                    control={control}
                    name="PolicyStatusActive"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => {
                          const currentValues = getValues();
                          const isChecked = e.target.checked;
                          const isAllPoliciesUnchecked =
                            !isChecked &&
                            !currentValues.PolicyStatusCancelled &&
                            !currentValues.PolicyStatusExpired &&
                            !currentValues.PolicyStatusRenewed;

                          if (!isAllPoliciesUnchecked) {
                            setValue("PolicyStatusActive", isChecked, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });

                            debouncedRefreshData();
                          }
                        }}
                        inputRef={field.ref}
                        name="PolicyStatusActive"
                      >
                        Active
                      </Checkbox>
                    )}
                  />
                  <Controller
                    control={control}
                    name="PolicyStatusExpired"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => {
                          const currentValues = getValues();
                          const isChecked = e.target.checked;
                          const isAllPoliciesUnchecked =
                            !isChecked &&
                            !currentValues.PolicyStatusCancelled &&
                            !currentValues.PolicyStatusActive &&
                            !currentValues.PolicyStatusRenewed;

                          if (isAllPoliciesUnchecked) {
                            setValue("PolicyStatusActive", true, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                          }
                          setValue("PolicyStatusExpired", isChecked, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });

                          debouncedRefreshData();
                        }}
                        inputRef={field.ref}
                        name="PolicyStatusExpired"
                      >
                        Expired
                      </Checkbox>
                    )}
                  />
                </Space>
                <Space direction="vertical">
                  <Controller
                    control={control}
                    name="PolicyStatusRenewed"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => {
                          const currentValues = getValues();
                          const isChecked = e.target.checked;
                          const isAllPoliciesUnchecked =
                            !isChecked &&
                            !currentValues.PolicyStatusCancelled &&
                            !currentValues.PolicyStatusActive &&
                            !currentValues.PolicyStatusExpired;

                          if (isAllPoliciesUnchecked) {
                            setValue("PolicyStatusActive", true, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                          }
                          setValue("PolicyStatusRenewed", isChecked, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });

                          debouncedRefreshData();
                        }}
                        inputRef={field.ref}
                        name="PolicyStatusRenewed"
                      >
                        Renewed
                      </Checkbox>
                    )}
                  />
                  <Controller
                    control={control}
                    name="PolicyStatusCancelled"
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => {
                          const currentValues = getValues();
                          const isChecked = e.target.checked;
                          const isAllPoliciesUnchecked =
                            !isChecked &&
                            !currentValues.PolicyStatusExpired &&
                            !currentValues.PolicyStatusActive &&
                            !currentValues.PolicyStatusRenewed;

                          if (isAllPoliciesUnchecked) {
                            setValue("PolicyStatusActive", true, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                          }
                          setValue("PolicyStatusCancelled", isChecked, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });

                          debouncedRefreshData();
                        }}
                        inputRef={field.ref}
                        name="PolicyStatusCancelled"
                      >
                        Cancelled
                      </Checkbox>
                    )}
                  />
                </Space>
              </Space>
            </Form.Item>
          </Col>
          <Col {...colSize}>
            <DynamicProducerSelect
              formLayout={secondLayout}
              formLabel="Producer 1"
              name="ProducerCode1Keys"
              setValue={setValue}
              control={control}
              producerCodesList={producerCodes1List}
              isProducerCodesListLoading={producerCodes1Loading}
              afterChange={() => {
                debouncedRefreshData();
              }}
            />
          </Col>
          <Col {...colSize}>
            <DynamicProducerSelect
              formLayout={secondLayout}
              formLabel="Producer 2"
              name="ProducerCode2Keys"
              setValue={setValue}
              control={control}
              producerCodesList={producerCodes2List}
              isProducerCodesListLoading={producerCodes2Loading}
              afterChange={() => {
                debouncedRefreshData();
              }}
            />
          </Col>
        </Row>
      </Form>
      <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
      <Row style={{ paddingTop: "16px" }}>
        <Col>
          <SearchableTable
            tableName={tableNames.ManageRenewals}
            keyColumn="DocumentKey"
            scroll={{ y: 550, x: 2300 }}
            dataSource={list}
            columns={manageRenewalsColumns()}
            loading={loading}
            size="small"
            buttons={[
              <Button
                size={"small"}
                disabled={!isValid || isExporting}
                onClick={() => {
                  updateProducerCodes.current = true;
                  debouncedRefreshData.cancel();
                  refreshData(getValues());
                }}
              >
                <ReloadOutlined />
                Refresh Table
              </Button>,
              <Button
                type="primary"
                size={"small"}
                disabled={isEmpty(selectedRows) || isExporting}
                loading={false}
                onClick={() => setViewDetails(true)}
              >
                <EditOutlined />
                Bulk Update
              </Button>,
              <Button
                size={"small"}
                disabled={isEmpty(tableCurrentDataSource)}
                loading={isExporting}
                onClick={() => {
                  exportTableData();
                }}
              >
                <FileExcelOutlined />
                Export
              </Button>,
            ]}
            rowSelection={{
              columnWidth: 30,
              selectedRowKeys: selectedRows,
              onChange: onSelectChange,
              fixed: true,
              hideSelectAll: false,
            }}
            rowKey={(r) => r.Id}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"No data"}
                />
              ),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default ManageRenewalsForm;
