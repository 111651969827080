import { FileTextOutlined, ReloadOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Empty,
  Form,
  Row,
  Space,
} from "antd";
import dayjs from "dayjs";
import { debounce, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDataDownloadDetails,
  listDataDownloadLog,
} from "../../../store/dataDownload/dataDownloadActions";
import AgencyMultiSelectEx from "../../shared/form/AgencyMultiSelectEx";
import { fixedDateRanges } from "../../shared/form/fixedDateRanges";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import AdvancedRangePicker from "../../shared/form/reactHookForm/AdvancedRangePicker";
import { dateFormat, toEndOfDay, toStartOfDay } from "../../shared/formatting";
import { agenciesListEqual } from "../../shared/sorting";
import { SearchableTable } from "../../shared/table/SearchableTable";
import { tableNames } from "../../shared/table/tableNames";
import DownloadDetailsTable from "./DownloadDetailsTable";
import { dataDownloadColumns } from "./dataDownloadColumns";
import { dataDownloadLogSchema } from "./dataDownloadSchemas";

//
const initialValues = {
  AgencyKeys: [],
  FixedDateRange: fixedDateRanges.LAST_X_DAYS,
  TransactionDate: ["", ""],
  ShowIncompleteRequests: true,
  ShowCompleteRequests: true,
  NumberOfDays: 2,
};

const firstLayout = {
  labelCol: {
    span: 5,
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 5 },
    xl: { span: 5 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    span: 19,
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 19 },
    xl: { span: 19 },
    xxl: { span: 19 },
  },
};

const secondLayout = {
  labelCol: {
    span: 5,
    sm: { span: 7 },
    md: { span: 6 },
    lg: { span: 5 },
    xl: { span: 7 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    span: 19,
    sm: { span: 17 },
    md: { span: 18 },
    lg: { span: 19 },
    xl: { span: 17 },
    xxl: { span: 19 },
  },
};

const DataDownloadLog = ({ agencies }) => {
  //
  const searchValues = useSelector((state) => state.dataDownload.searchValues);
  const list = useSelector((state) => state.dataDownload.dataDownloadLog);

  // Form
  const {
    reset,
    control,
    setValue,
    trigger,
    getValues,
    formState: { defaultValues, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: searchValues != null ? searchValues : initialValues,
    resolver: yupResolver(dataDownloadLogSchema),
  });

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);
  const [viewDetails, setViewDetails] = useState(false);

  //
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const loading = useSelector((state) => state.dataDownload.loading);

  //
  const refreshLog = (values = getValues()) => {
    dispatch && dispatch(listDataDownloadLog(values));
  };

  const debouncedRefreshLog = useMemo(() => debounce(refreshLog, 1000), []);

  //
  useEffect(() => {
    return () => {
      debouncedRefreshLog.cancel();
    };
  }, []);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      if (searchValues == null || searchValues == false) {
        resetFieldsToDefault();
      } else {
        reset({ ...searchValues });
        trigger();
      }

      //
      debouncedRefreshLog.cancel();
      refreshLog();
    }
  }, [agencies, homeAgency]);

  //
  const resetFieldsToDefault = () => {
    var initialAgency = getInitialAgency(homeAgency, agencies);
    var newValues = {
      ...initialValues,
      AgencyKeys: [initialAgency],
      TransactionDate: [
        toStartOfDay(dayjs().endOf("day").subtract(1, "days")),
        toEndOfDay(dayjs().endOf("day")),
      ],
    };
    reset(newValues);
    trigger();
  };

  const viewDetailsOnCloseHandler = () => {
    setViewDetails(false);
    dispatch(clearDataDownloadDetails());
  };

  return (
    <div>
      <Drawer
        title={
          <Space>
            <FileTextOutlined />
            Download Request Details
          </Space>
        }
        width={640}
        onClose={viewDetailsOnCloseHandler}
        open={viewDetails}
        bodyStyle={{ paddingTop: 4 }}
      >
        <DownloadDetailsTable />
      </Drawer>
      <Form
        size="small"
        autoComplete="off"
        onFinish={() => {}}
        style={{ marginTop: "16px" }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={12}>
            <AgencyMultiSelectEx
              layout={firstLayout}
              formLabel="Agency"
              required={true}
              agencies={currentAgencies}
              isLoading={isEmpty(defaultValues.AgencyKeys)}
              setValue={setValue}
              control={control}
              name="AgencyKeys"
              onChange={(newAgencyKeys) => {
                if (newAgencyKeys != null && newAgencyKeys.length > 0) {
                  debouncedRefreshLog();
                }
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={12}>
            <AdvancedRangePicker
              formItem={{
                layout: secondLayout,
                required: true,
                label: "Transaction Date",
              }}
              control={control}
              setValue={setValue}
              allowClear={false}
              disableFutureDates={true}
              name="TransactionDate"
              format={dateFormat}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
              afterChange={() => debouncedRefreshLog()}
            />
            <Form.Item
              {...secondLayout}
              label="Show"
              name="Show"
              required
              valuePropName="checked"
              style={{ marginBottom: 0 }}
            >
              <Space direction="vertical">
                <Controller
                  control={control}
                  name="ShowIncompleteRequests"
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => {
                        const currentValues = getValues();
                        const incompleteRequests = e.target.checked;
                        const completeRequests =
                          !currentValues.ShowCompleteRequests &&
                          !e.target.checked
                            ? true
                            : currentValues.ShowCompleteRequests;

                        setValue("ShowIncompleteRequests", incompleteRequests, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        if (
                          currentValues.ShowCompleteRequests != completeRequests
                        ) {
                          setValue("ShowCompleteRequests", completeRequests, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }

                        debouncedRefreshLog();
                      }}
                      inputRef={field.ref}
                      name="ShowIncompleteRequests"
                    >
                      Incomplete Requests
                    </Checkbox>
                  )}
                />
                <Controller
                  control={control}
                  name="ShowCompleteRequests"
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      name="ShowCompleteRequests"
                      onChange={(e) => {
                        const currentValues = getValues();
                        const completeRequests = e.target.checked;
                        const incompleteRequests =
                          !currentValues.ShowIncompleteRequests &&
                          !e.target.checked
                            ? true
                            : currentValues.ShowIncompleteRequests;

                        setValue("ShowCompleteRequests", completeRequests, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        if (
                          currentValues.ShowIncompleteRequests !=
                          incompleteRequests
                        ) {
                          setValue(
                            "ShowIncompleteRequests",
                            incompleteRequests,
                            {
                              shouldValidate: true,
                              shouldDirty: true,
                            }
                          );
                        }

                        debouncedRefreshLog();
                      }}
                      inputRef={field.ref}
                    >
                      Complete Requests
                    </Checkbox>
                  )}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider dashed style={{ marginTop: 16, marginBottom: 16 }} />
      <Row style={{ paddingTop: "16px" }}>
        <Col>
          <SearchableTable
            tableName={tableNames.DataDownloadLog}
            keyColumn="DocumentKey"
            scroll={{ y: 550, x: 1060 }}
            sticky
            dataSource={list}
            columns={dataDownloadColumns(setViewDetails)}
            loading={loading}
            size="small"
            buttons={[
              <Button
                size={"small"}
                disabled={!isValid}
                onClick={() => {
                  refreshLog();
                }}
              >
                <ReloadOutlined />
                Refresh Table
              </Button>,
            ]}
            rowKey={(r) => r.Id}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"No data"}
                />
              ),
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
export default DataDownloadLog;
