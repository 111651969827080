import * as yup from "yup";
import {
  requiredMessage,
  alphanumericRegex,
  alphanumericMessage,
  minMessage,
  maxMessage,
  numericRegex,
  numericMessage,
  dateRangeRequiredMessage,
} from "../../../components/shared/form/formValidatonRules";
import { types } from "./movementTypes";

export const step1Schema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
  InventoryMovementType: yup.number().required(requiredMessage),
  ToAgentUserKey: yup
    .number()
    .nullable()
    .when("InventoryMovementType", {
      is: types.AllocationFromRoadAgentToRoadAgent,
      then: yup.number().required(requiredMessage).nullable(),
    })
    .when("InventoryMovementType", {
      is: types.AllocationFromOfficeToRoadAgent,
      then: yup.number().required(requiredMessage).nullable(),
    }),
  FromAgentUserKey: yup
    .number()
    .nullable()
    .when("InventoryMovementType", {
      is: types.ReturnFromRoadAgentToOffice,
      then: yup.number().required(requiredMessage).nullable(),
    })
    .when("InventoryMovementType", {
      is: types.AllocationFromRoadAgentToRoadAgent,
      then: yup.number().required(requiredMessage).nullable(),
    }),
});

export const receiveStockFromICBCSchema = yup.object().shape({
  TransactionDate: yup.string().required(requiredMessage).nullable(),
  StockCentreConfNo: yup
    .string()
    .matches(numericRegex, numericMessage)
    .nullable(),
});

export const returnStockToICBCSchema = yup.object().shape({
  TransactionDate: yup.string().required(requiredMessage).nullable(),
  StockCentreConfNo: yup
    .string()
    .matches(numericRegex, numericMessage)
    .nullable(),
});

export const transferStockToAnotherBrokerSchema = yup.object().shape({
  AgencyNumber: yup.string(),
  TransactionDate: yup.string().required(requiredMessage).nullable(),
  TransferredToAgencyNumber: yup
    .string()
    .min(5, minMessage)
    .max(5, maxMessage)
    .required(requiredMessage)
    .matches(numericRegex, numericMessage)
    .nullable()
    .when("AgencyNumber", (AgencyNumber, schema) => {
      return schema.test({
        test: (TransferredToAgencyNumber) =>
          !!AgencyNumber && TransferredToAgencyNumber !== AgencyNumber,
        message: "Same as transferring agency",
      });
    }),
  StockCentreConfNo: yup
    .string()
    .matches(numericRegex, numericMessage)
    .nullable(),
});

export const allocateInventorySchema = yup.object().shape({
  TransactionDate: yup.string().required(requiredMessage).nullable(),
});

export const addPlatesSchema = yup.object().shape({
  PlateItemNumberFrom: yup
    .string()
    .required("")
    .min(2, minMessage)
    .max(6, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  PlateItemNumberTo: yup
    .string()
    .required("")
    .min(2, minMessage)
    .max(6, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  PlateTypeKey: yup.number().required(""),
});

export const transferPlatesSchema = yup.object().shape({
  PlateItemNumberFrom: yup
    .string()
    .required(requiredMessage)
    .min(2, minMessage)
    .max(6, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
  PlateItemNumberTo: yup
    .string()
    .required(requiredMessage)
    .min(2, minMessage)
    .max(6, maxMessage)
    .matches(alphanumericRegex, alphanumericMessage),
});

export const searchMovementsSchema = yup.object().shape({
  AgencyKey: yup.number().required(requiredMessage),
  StockCentreConfNo: yup.string(),
  TransactionDate: yup.array().required(dateRangeRequiredMessage).nullable(),
});
