import { Actions } from "./agencyConstants";
import produce from "immer";

const initialState = {
  list: [],
  selected: null,
  loading: false,
  listCompact: [],
  agenciesForSupport: { list: [], loading: false },
  broker: { list: [], loading: false },
};

export const reducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.LIST_AGENCIES:
      case Actions.LIST_AGENCIES_COMPACT:
      case Actions.LIST_AGENCIES_COMPACT_FOR_BATCHING:
      case Actions.LIST_AGENCIES_COMPACT_FOR_PRODUCER_CODES:
      case Actions.READ_AGENCY:
      case Actions.UPDATE_AGENCY:
      case Actions.CREATE_AGENCY:
        draft.loading = true;
        return draft;

      case Actions.LIST_AGENCIES_COMPACT_FOR_SUPPORT:
        draft.agenciesForSupport.loading = true;
        return draft;

      case Actions.LIST_AGENCIES_COMPACT_FOR_SUPPORT_SUCCEEDED:
        draft.agenciesForSupport.loading = false;
        draft.agenciesForSupport.list = action.payload;
        return draft;

      case Actions.LIST_AGENCIES_SUCCEEDED:
        draft.loading = false;
        draft.list = action.payload;
        return draft;

      case Actions.LIST_AGENCIES_COMPACT_SUCCEEDED:
      case Actions.LIST_AGENCIES_COMPACT_FOR_BATCHING_SUCCEEDED:
      case Actions.LIST_AGENCIES_COMPACT_FOR_PRODUCER_CODES_SUCCEEDED:
        draft.loading = false;
        draft.listCompact = action.payload;
        return draft;

      case Actions.READ_AGENCY_SUCCEEDED:
        draft.selected = action.payload.Data;
        draft.operationResult =
          action.payload.Operation.Outcome !== "Success"
            ? action.payload.Operation
            : null;
        draft.loading = false;
        return draft;

      case Actions.CLEAR_OPERATION_RESULT:
        draft.operationResult = null;
        return draft;

      case Actions.CLEAR_SELECTED:
        draft.operationResult = null;
        draft.selected = null;
        return draft;

      case Actions.UPDATE_AGENCY_SUCCEEDED:
      case Actions.CREATE_AGENCY_SUCCEEDED:
        if (action.payload.Operation.Outcome === "Success") {
          draft.selected = action.payload.Data;
        }
        draft.operationResult = action.payload.Operation;
        draft.loading = false;
        return draft;

      case Actions.CLEAR_LIST_COMPACT:
        draft.listCompact = [];
        return draft;

      case Actions.CLEAR_LIST_COMPACT_FOR_SUPPORT:
        draft.agenciesForSupport.list = [];
        return draft;

      case Actions.CLEAR_BROKER_LIST_COMPACT:
        draft.broker.list = [];
        return draft;

      case Actions.LIST_BROKERS_COMPACT:
        draft.broker.loading = true;
        return draft;

      case Actions.LIST_BROKERS_COMPACT_SUCCEEDED:
        draft.broker.loading = false;
        draft.broker.listCompact = action.payload.Data;
        return draft;
      default:
        return state;
    }
  });
};
