import { Select } from "antd";

const { Option } = Select;

export const sortGroupDrillDownFields = {
  AGENCY_NAME: "AgencyNameAndNo",
  BATCH_NUMBER: "BatchNo",
  PRODUCER_1_NAME: "ProducerName1",
  PRODUCER_2_NAME: "ProducerName2",
  PRODUCER_1_CODE: "ProducerCode1",
  PRODUCER_2_CODE: "ProducerCode2",
  MONTH: "ProdMonthName",
  CITY: "City",
  RATE_CLASS: "RateClass",
  FLEET_GARAGE_NUMBER: "FleetGarageNumber",
  PRODUCT: "Product",
};
export const drillDownSortFieldOptions = [
  { label: "Agency", value: sortGroupDrillDownFields.AGENCY_NAME },
  { label: "Month", value: sortGroupDrillDownFields.MONTH },
  { label: "Batch Number", value: sortGroupDrillDownFields.BATCH_NUMBER },
  {
    label: "Fleet/Garage Number",
    value: sortGroupDrillDownFields.FLEET_GARAGE_NUMBER,
  },
  { label: "City", value: sortGroupDrillDownFields.CITY },
  { label: "Producer 1", value: sortGroupDrillDownFields.PRODUCER_1_CODE },
  { label: "Producer 2", value: sortGroupDrillDownFields.PRODUCER_2_CODE },
  { label: "ICBC Product", value: sortGroupDrillDownFields.PRODUCT },
];

export const salesAndCondensedDrillDownFieldOptions = [
  { label: "Agency", value: sortGroupDrillDownFields.AGENCY_NAME },
  { label: "Month", value: sortGroupDrillDownFields.MONTH },
  {
    label: "Fleet/Garage Number",
    value: sortGroupDrillDownFields.FLEET_GARAGE_NUMBER,
  },
  { label: "City", value: sortGroupDrillDownFields.CITY },
  { label: "Producer 1", value: sortGroupDrillDownFields.PRODUCER_1_CODE },
  { label: "Producer 2", value: sortGroupDrillDownFields.PRODUCER_2_CODE },
  { label: "ICBC Product", value: sortGroupDrillDownFields.PRODUCT },
];

export const comparisonDrillDownFieldOptions = [
  { label: "Agency", value: sortGroupDrillDownFields.AGENCY_NAME },
  { label: "Month", value: sortGroupDrillDownFields.MONTH },
  {
    label: "Fleet/Garage Number",
    value: sortGroupDrillDownFields.FLEET_GARAGE_NUMBER,
  },
  { label: "City", value: sortGroupDrillDownFields.CITY },
  { label: "Producer 1", value: sortGroupDrillDownFields.PRODUCER_1_CODE },
  { label: "Producer 2", value: sortGroupDrillDownFields.PRODUCER_2_CODE },
];

export const sortGroupFieldOptions = [
  { label: "Agency", value: sortGroupDrillDownFields.AGENCY_NAME },
  { label: "Month", value: sortGroupDrillDownFields.MONTH },
  { label: "Batch Number", value: sortGroupDrillDownFields.BATCH_NUMBER },
  { label: "Producer 1 Name", value: sortGroupDrillDownFields.PRODUCER_1_NAME },
  { label: "Producer 2 Name", value: sortGroupDrillDownFields.PRODUCER_2_NAME },
  { label: "Producer 1 Code", value: sortGroupDrillDownFields.PRODUCER_1_CODE },
  { label: "Producer 2 Code", value: sortGroupDrillDownFields.PRODUCER_2_CODE },
  {
    label: "Fleet/Garage Number",
    value: sortGroupDrillDownFields.FLEET_GARAGE_NUMBER,
  },
  { label: "City", value: sortGroupDrillDownFields.CITY },
  { label: "Rate Class", value: sortGroupDrillDownFields.RATE_CLASS },
  { label: "ICBC Product", value: sortGroupDrillDownFields.PRODUCT },
];

export const reportTypes = {
  SUMMARY: "Summary",
  SALES_SUMMARY: "SalesSummary",
  DETAIL: "Detail",
  COMPARISON: "Comparison",
  SUMMARY_CONDENSED: "SummaryCondensed",
  COMPARISON_CONDENSED: "ComparisonCondensed",
};

export const reportTypeOptions = [
  <Option value={reportTypes.SUMMARY} key="0">
    Summary
  </Option>,
  <Option value={reportTypes.SALES_SUMMARY} key="1">
    Sales
  </Option>,
  <Option value={reportTypes.DETAIL} key="2">
    Detail
  </Option>,
  <Option value={reportTypes.COMPARISON} key="3">
    Comparison
  </Option>,
  <Option value={reportTypes.SUMMARY_CONDENSED} key="4">
    Summary (Condensed)
  </Option>,
  <Option value={reportTypes.COMPARISON_CONDENSED} key="5">
    Comparison (Condensed)
  </Option>,
];
