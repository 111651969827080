import React, { useEffect, useState } from "react";

// import "antd/dist/antd.css";

import {
  BarChartOutlined,
  DatabaseOutlined,
  DollarCircleOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  GroupOutlined,
  HomeOutlined,
  PlusSquareOutlined,
  ReloadOutlined,
  RightOutlined,
  SearchOutlined,
  SettingOutlined,
  ShopOutlined,
  SnippetsOutlined,
  SwapOutlined,
  TeamOutlined,
  CalculatorOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { clearNewBatchAction } from "../../store/batching/batchingActions";
import getMenuItemsVisibility from "./getMenuItemsVisibility";
import { usePreservableState } from "../shared/PreservableStatePageWrapper";

const SideMenu = () => {
  const { isRestorable, getPreviousPagePath } = usePreservableState();
  const dispatch = useDispatch();
  const location = useLocation();
  const actorDetails = useSelector((state) => state.actor.details.data);
  const actorDetailsLoading = useSelector(
    (state) => state.actor.details.loading
  );
  const currentBatchingStep = useSelector(
    (state) => state.batching.newBatch.ui.processBatchCurrentStep
  );
  const actorLinkedAndEnabled =
    actorDetails != null &&
    actorDetails.IsUserOrBrokerageDisabled === false;
  let menuVisibility = getMenuItemsVisibility(actorDetails);

  const homeMenuItem = {
    key: "/home",
    title: "",
    label: (
      <>
        <HomeOutlined
          style={{
            fontSize: "16px",
            color: "#1c4658",
            strokeWidth: "30",
            stroke: "#1c4658",
          }}
        />
        <span className="side-menu-text">Home</span>
        <Link to="/home" />
      </>
    ),
  };

  const items =
    actorLinkedAndEnabled && !actorDetailsLoading
      ? [
          homeMenuItem,

          ...(menuVisibility.Documents
            ? [
                {
                  key: "documentsSubmenu",
                  icon: (
                    <SnippetsOutlined
                      style={{
                        fontSize: "16px",
                        color: "#1c4658",
                        strokeWidth: "30",
                        stroke: "#1c4658",
                      }}
                    />
                  ),
                  label: <span className="side-menu-text">Documents</span>,
                  children: [
                    {
                      key: "/documents",
                      label: (
                        <>
                          <FileSearchOutlined />
                          <span>Search</span>
                          <Link to="/documents" />
                        </>
                      ),
                    },
                    ...(menuVisibility.AddDocument
                      ? [
                          {
                            key: "/manualdocument",
                            label: (
                              <>
                                <FileAddOutlined />
                                <span>Add Manual</span>
                                <Link to="/manualdocument" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.MoveDocuments
                      ? [
                          {
                            key: "/movedocuments",
                            label: (
                              <>
                                <SwapOutlined />
                                <span>Move</span>
                                <Link to="/movedocuments" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.DataDownload
                      ? [
                          {
                            key: "/downloads",
                            label: (
                              <>
                                <DownloadOutlined />
                                <span>Downloads</span>
                                <Link to="/downloads" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.OnlineRenewals
                      ? [
                          {
                            key: "/onlinerenewals",
                            label: (
                              <>
                                <ReloadOutlined />
                                <span>Online Renewals</span>
                                <Link to="/onlinerenewals" />
                              </>
                            ),
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          ...(menuVisibility.Batching
            ? [
                {
                  key: "batchingSubmenu",
                  icon: (
                    <GroupOutlined
                      style={{
                        fontSize: "16px",
                        color: "#1c4658",
                        strokeWidth: "30",
                        stroke: "#1c4658",
                      }}
                    />
                  ),
                  label: <span className="side-menu-text">Batching</span>,
                  children: [
                    {
                      key: "/batches",
                      label: (
                        <>
                          <SearchOutlined />
                          <span>Search</span>
                          <Link to="/batches" />
                        </>
                      ),
                    },
                    ...(menuVisibility.AddBatch
                      ? [
                          {
                            key: "/createBatchForm",
                            label: (
                              <>
                                <PlusSquareOutlined />
                                <span>Process New</span>
                                <Link
                                  to="/createBatchForm"
                                  onClick={() => {
                                    if (currentBatchingStep === 2) {
                                      dispatch(clearNewBatchAction());
                                    }
                                  }}
                                />
                              </>
                            ),
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          ...(menuVisibility.Commission && menuVisibility.FleetGarageCommission
            ? [
                {
                  key: "commissionSubmenu",
                  icon: (
                    <DollarCircleOutlined
                      style={{
                        fontSize: "16px",
                        color: "#1c4658",
                        strokeWidth: "30",
                        stroke: "#1c4658",
                      }}
                    />
                  ),
                  label: <span className="side-menu-text">Commissions</span>,
                  children: [
                    {
                      key: "/commissionReport",
                      label: (
                        <>
                          <BarChartOutlined />
                          <span>Report</span>
                          <Link to="/commissionReport" />
                        </>
                      ),
                    },
                    {
                      key: "/fleetGarageCommissionReport",
                      label: (
                        <>
                          <BarChartOutlined />
                          <span>Fleet and Garage Report</span>
                          <Link to="/fleetGarageCommissionReport" />
                        </>
                      ),
                    },
                  ],
                },
              ]
            : []),
          ...(menuVisibility.Commission && !menuVisibility.FleetGarageCommission
            ? [
                {
                  key: "/commissionReport",
                  title: "",
                  label: (
                    <>
                      <BarChartOutlined
                        style={{
                          fontSize: "16px",
                          color: "#1c4658",
                          strokeWidth: "30",
                          stroke: "#1c4658",
                        }}
                      />

                      <span className="side-menu-text">Commissions</span>

                      <Link to="/commissionReport" />
                    </>
                  ),
                },
              ]
            : []),
          ...(menuVisibility.Inventory
            ? [
                {
                  key: "inventorySubmenu",
                  icon: (
                    <DatabaseOutlined
                      style={{
                        fontSize: "16px",
                        color: "#1c4658",
                        strokeWidth: "30",
                        stroke: "#1c4658",
                      }}
                    />
                  ),
                  label: <span className="side-menu-text">Inventory</span>,
                  children: [
                    ...(menuVisibility.SearchInventory
                      ? [
                          {
                            key: "searchInventorySubmenu",
                            icon: <SearchOutlined />,
                            label: "Search",
                            children: [
                              {
                                key: "/inventoryItems",
                                label: (
                                  <>
                                    <span>Inventory</span>
                                    <Link to="/inventoryItems" />
                                  </>
                                ),
                              },
                              ...(menuVisibility.SearchMovements
                                ? [
                                    {
                                      key: "/movements",
                                      label: (
                                        <>
                                          <span>Movements</span>
                                          <Link to="/movements" />
                                        </>
                                      ),
                                    },
                                  ]
                                : []),
                            ],
                          },
                        ]
                      : []),

                    ...(menuVisibility.MoveInventory
                      ? [
                          {
                            key: "moveInventorySubmenu",
                            icon: <SwapOutlined />,
                            label: "Move",
                            children: [
                              {
                                key: "/receiveInventorySteps",
                                label: (
                                  <>
                                    <span>Receive</span>
                                    <Link to="/receiveInventorySteps" />
                                  </>
                                ),
                              },
                              {
                                key: "/transferInventorySteps",
                                label: (
                                  <>
                                    <span>Transfer</span>
                                    <Link to="/transferInventorySteps" />
                                  </>
                                ),
                              },
                              {
                                key: "/returnToICBCSteps",
                                label: (
                                  <>
                                    <span>Return to ICBC</span>
                                    <Link to="/returnToICBCSteps" />
                                  </>
                                ),
                              },
                              {
                                key: "/allocateInventorySteps",
                                label: (
                                  <>
                                    <span>Allocate</span>
                                    <Link to="/allocateInventorySteps" />
                                  </>
                                ),
                              },
                            ],
                          },
                        ]
                      : []),
                    ...(menuVisibility.MaintainInventory
                      ? [
                          {
                            key: "/maintainInventory",
                            label: (
                              <>
                                <DatabaseOutlined />
                                <span>Maintain</span>
                                <Link to="/maintainInventory" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.InventoryReports
                      ? [
                          {
                            key: "inventoryReportsSubmenu",
                            icon: <BarChartOutlined />,
                            label: "Reports",
                            children: [
                              {
                                key: "/roadAgentDailyStock",
                                label: (
                                  <>
                                    <span>Road Agent's Daily Stock</span>
                                    <Link to="/roadAgentDailyStock" />
                                  </>
                                ),
                              },
                              {
                                key: "/onHandInventoryReport",
                                label: (
                                  <>
                                    <span>On-hand Inventory</span>
                                    <Link to="/onHandInventoryReport" />
                                  </>
                                ),
                              },
                              {
                                key: "/inventoryLogReport",
                                label: (
                                  <>
                                    <span>Inventory Log</span>
                                    <Link to="/inventoryLogReport" />
                                  </>
                                ),
                              },
                              ...(menuVisibility.AuditInventoryReport
                                ? [
                                    {
                                      key: "/inventoryAuditReport",
                                      label: (
                                        <>
                                          <span>Inventory Audit</span>
                                          <Link to="/inventoryAuditReport" />
                                        </>
                                      ),
                                    },
                                  ]
                                : []),
                            ],
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          ...(menuVisibility.Renewals
            ? [
                {
                  key: "renewalsSubmenu",
                  icon: (
                    <FileSyncOutlined
                      style={{
                        fontSize: "16px",
                        color: "#1c4658",
                        strokeWidth: "30",
                        stroke: "#1c4658",
                      }}
                    />
                  ),
                  label: <span className="side-menu-text">Renewals</span>,
                  children: [
                    ...(menuVisibility.RenewalsReport
                      ? [
                          {
                            key: "/renewalListReport",
                            label: (
                              <>
                                <BarChartOutlined />
                                <span>Renewal List Report</span>
                                <Link to="/renewalListReport" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.RetentionReport
                      ? [
                          {
                            key: "retentionSubmenu",
                            icon: <BarChartOutlined />,
                            label: "Retention",
                            children: [
                              {
                                key: "/retentionSummaryReport",
                                label: (
                                  <>
                                    <span>Retention Summary Report</span>
                                    <Link to="/retentionSummaryReport" />
                                  </>
                                ),
                              },
                              {
                                key: "/bcarRetentionReport",
                                label: (
                                  <>
                                    <span>BCautorenew Retention Report</span>
                                    <Link to="/bcarRetentionReport" />
                                  </>
                                ),
                              },
                            ],
                          },
                        ]
                      : []),
                    ...(menuVisibility.ManageRenewals
                      ? [
                          {
                            key: "/managerenewals",
                            label: (
                              <>
                                <SettingOutlined />
                                <span>Manage</span>
                                <Link to="/managerenewals" />
                              </>
                            ),
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          ...(menuVisibility.Reports
            ? [
                {
                  key: "reportsSubmenu",
                  icon: (
                    <BarChartOutlined
                      style={{
                        fontSize: "16px",
                        color: "#1c4658",
                        strokeWidth: "30",
                        stroke: "#1c4658",
                      }}
                    />
                  ),
                  label: <span className="side-menu-text">Reports</span>,
                  children: [
                    ...(menuVisibility.ProductionReports
                      ? [
                          {
                            key: "/productionReport",
                            label: (
                              <>
                                <BarChartOutlined />
                                <span>Production</span>
                                <Link to="/productionReport" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.ProductionSummaryByDayHourReport
                      ? [
                          {
                            key: "/productionByDayHourReport",
                            label: (
                              <>
                                <BarChartOutlined />
                                <span>Production Summary by Day/Hour</span>
                                <Link to="/productionByDayHourReport" />
                              </>
                            ),
                          },
                          {
                            type: "divider",
                            dashed: true,
                          },
                        ]
                      : []),

                    ...(menuVisibility.CoverageSummaryReport
                      ? [
                          {
                            key: "/coverageSummaryReport",
                            label: (
                              <>
                                <BarChartOutlined />
                                <span>Coverage Summary</span>
                                <Link to="/coverageSummaryReport" />
                              </>
                            ),
                          },
                        ]
                      : []),

                    ...(menuVisibility.DealerBilling
                      ? [
                          {
                            key: "/dealerBillingReport",
                            label: (
                              <>
                                <BarChartOutlined />
                                <span>Dealer Billing</span>
                                <Link to="/dealerBillingReport" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.DataCollectionReport
                      ? [
                          {
                            type: "divider",
                            dashed: true,
                          },
                          {
                            key: "/dataCollectionReport",
                            label: (
                              <>
                                <BarChartOutlined />
                                <span>Customer Data Collection</span>
                                <Link to="/dataCollectionReport" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.TextSummaryByReplyType
                      ? [
                          {
                            key: "/textSummaryReport",
                            label: (
                              <>
                                <BarChartOutlined />
                                <span>Text Reminder Summary By Reply Type</span>
                                <Link to="/textSummaryReport" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.ScheduleOfVehicles
                      ? [
                          {
                            type: "divider",
                            dashed: true,
                          },
                          {
                            key: "/scheduleOfVehiclesReport",
                            label: (
                              <>
                                <BarChartOutlined />
                                <span>Schedule Of Vehicles Report</span>
                                <Link to="/scheduleOfVehiclesReport" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.ConfirmationOfInsurance
                      ? [
                          {
                            key: "/confirmationOfInsuranceReport",
                            label: (
                              <>
                                <BarChartOutlined />
                                <span>Confirmation Of Insurance Report</span>
                                <Link to="/confirmationOfInsuranceReport" />
                              </>
                            ),
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          ...(menuVisibility.LoanCalculator
            ? [
                {
                  key: "/loancalculator",
                  title: "",
                  label: (
                    <>
                      <CalculatorOutlined
                        style={{
                          fontSize: "16px",
                          color: "#1c4658",
                          strokeWidth: "30",
                          stroke: "#1c4658",
                        }}
                      />
                      <span className="side-menu-text">Loan Calculator</span>
                      <Link to="/loancalculator" />
                    </>
                  ),
                },
              ]
            : []),
          ...(menuVisibility.Admin
            ? [
                {
                  key: "adminSubmenu",
                  icon: (
                    <SettingOutlined
                      style={{
                        fontSize: "16px",
                        color: "#1c4658",
                        strokeWidth: "30",
                        stroke: "#1c4658",
                      }}
                    />
                  ),
                  label: <span className="side-menu-text">Administration</span>,
                  children: [
                    ...(menuVisibility.Agencies
                      ? [
                          {
                            key: "/agencies",
                            label: (
                              <>
                                <ShopOutlined />
                                <span>Agencies</span>
                                <Link to="/agencies" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    {
                      key: "/users",
                      label: (
                        <>
                          <TeamOutlined />
                          <span>Users</span>
                          <Link to="/users" />
                        </>
                      ),
                    },
                    {
                      key: "/legacyUsers",
                      label: (
                        <>
                          <TeamOutlined />
                          <span>Legacy Autolink Users</span>
                          <Link to="/legacyUsers" />
                        </>
                      ),
                    },
                    ...(menuVisibility.Producers
                      ? [
                          {
                            key: "/producers",
                            label: (
                              <>
                                <SettingOutlined />
                                <span>Producer Codes</span>
                                <Link to="/producers" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.CommissionRates
                      ? [
                          {
                            key: "/commissionrates",
                            label: (
                              <>
                                <DollarCircleOutlined />
                                <span>Commission Rates</span>
                                <Link to="/commissionrates" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.Announcements
                      ? [
                          {
                            key: "/announcements",
                            label: (
                              <>
                                <SettingOutlined />
                                <span>Announcements</span>
                                <Link to="/announcements" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.AppLogs
                      ? [
                          {
                            key: "/appLogs",
                            label: (
                              <>
                                <SettingOutlined />
                                <span>App Logs</span>
                                <Link to="/appLogs" />
                              </>
                            ),
                          },
                        ]
                      : []),
                    ...(menuVisibility.FleetPolicyToClientMap
                      ? [
                          {
                            key: "/fleetPolicyToClientMap",
                            label: (
                              <>
                                <SettingOutlined />
                                <span>Fleet / Policy To Epic Client Map</span>
                                <Link to="/fleetPolicyToClientMap" />
                              </>
                            ),
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
        ]
      : [homeMenuItem];

  const getSelectedKeyFromLocation = (locationPathName) => {
    return locationPathName === "/" ||
      locationPathName === "" ||
      locationPathName.startsWith("/callback")
      ? "/home"
      : locationPathName.endsWith("/") &&
        locationPathName !== "/" &&
        locationPathName.length > 1
      ? locationPathName.slice(0, -1)
      : locationPathName;
  };

  const [selectedKeys, setSelectedKeys] = useState([
    getSelectedKeyFromLocation(location?.pathname),
  ]);

  useEffect(() => {
    // Current key (curated, ie: '/home/' become '/home')
    var currentKey = getSelectedKeyFromLocation(location.pathname);

    // Make sure the URL only contains one '/' symbol, otherwise return null
    const currentKeySplit = currentKey.split("/");
    currentKey =
      currentKeySplit.length < 3
        ? currentKey
        : currentKeySplit.length === 3 && currentKeySplit[2].length === 0
        ? currentKeySplit.slice(0, 2).join("/")
        : null;

    // In case it's null (the URL is a composed URL i.e: /batches/update/23232), check if it's restorable
    if (currentKey == null && isRestorable) {
      const splitPreviousPagePath = getPreviousPagePath().split("/");
      currentKey =
        splitPreviousPagePath.length < 2
          ? null
          : splitPreviousPagePath.slice(0, 2).join("/");
    }

    //
    if (currentKey !== selectedKeys[0] && currentKey != null) {
      setSelectedKeys([currentKey]);
    }
  }, [location.pathname]);

  return (
    <Menu
      mode="vertical"
      items={items}
      expandIcon={
        <RightOutlined style={{ color: "#1c4658", fontSize: "12px" }} />
      }
      defaultSelectedKeys={[getSelectedKeyFromLocation(location?.pathname)]}
      selectedKeys={selectedKeys}
      onSelect={({ selectedKeys }) => {
        setSelectedKeys(selectedKeys);
      }}
    />
  );
};

export default SideMenu;
