import { RightCircleOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Divider, Form, Radio, Row, Space } from "antd";
import { isEmpty, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { listAgenciesCompactAction } from "../../../store/agency/agencyActions";
import {
  clearSelectedSourceAgentAction,
  clearSelectedTargetAgentAction,
  getNewInventoryMovementAction,
} from "../../../store/inventory/inventoryActions";
import AgencySelectEx from "../../shared/form/AgencySelectEx";
import { getInitialAgency } from "../../shared/form/getInitialAgency";
import CustomRadioGroup from "../../shared/form/reactHookForm/CustomRadioGroup";
import { ManageInventory } from "../../shared/securityRoleTasks";
import { agenciesListEqual } from "../../shared/sorting";
import { step1Schema } from "../shared/moveInventorySchemas";
import { movementOptions, types } from "../shared/movementTypes";
import SourceAgentSelect from "./SourceAgentSelect";
import TargetAgentSelect from "./TargetAgentSelect";

//
const initialValues = {
  FromAgentUserKey: null,
  ToAgentUserKey: null,
  AgencyKey: "",
  InventoryMovementType: types.AllocationFromOfficeToRoadAgent,
};

const AllocateInventoryStep1 = () => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    trigger,
    formState: { isValid, defaultValues },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(step1Schema),
  });

  const agencyKey = watch("AgencyKey");
  const inventoryMovementType = watch("InventoryMovementType");

  //
  const dispatch = useDispatch();

  //
  const [currentAgencies, setCurrentAgencies] = useState([]);

  //
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const header = useSelector(
    (state) =>
      state.inventory.moveInventory.newMovement.entity &&
      state.inventory.moveInventory.newMovement.entity.Header
  );
  const loading = useSelector(
    (state) => state.inventory.moveInventory.newMovement.loading
  );
  const selectedTargetAgent = useSelector(
    (state) => state.inventory.moveInventory.newMovement.targetAgents.selected
  );
  const selectedSourceAgent = useSelector(
    (state) => state.inventory.moveInventory.newMovement.sourceAgents.selected
  );

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, ManageInventory));
    }
  }, [brokerId]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);
      var initialAgency = getInitialAgency(homeAgency, agencies);
      if (!isNull(header)) {
        if (agencies.find((a) => a.Id === header.AgencyKey)) {
          initialAgency = header.AgencyKey;
        }
      }

      var defaultMovementType = !isNull(header)
        ? header.InventoryMovementType
        : initialValues.InventoryMovementType;

      reset({
        FromAgentUserKey: selectedSourceAgent,
        ToAgentUserKey: selectedTargetAgent,
        AgencyKey: initialAgency,
        InventoryMovementType: defaultMovementType,
      });

      trigger();
    }
  }, [agencies, homeAgency]);

  const onSubmitHandler = (values) => {
    dispatch(
      getNewInventoryMovementAction(values, "ALLOCATE_INVENTORY", {
        FromAgentUserKey: values.FromAgentUserKey,
        ToAgentUserKey: values.ToAgentUserKey,
        AgencyKey: values.AgencyKey,
        InventoryMovementType: values.InventoryMovementType,
      })
    );
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Form
            size="small"
            autoComplete="off"
            onFinish={() => handleSubmit(onSubmitHandler)()}
          >
            <Row>
              <Col span={10}>
                <AgencySelectEx
                  layout={{
                    labelCol: { span: 4 },
                    wrapperCol: { span: 20 },
                  }}
                  formLabel="Agency"
                  name="AgencyKey"
                  required={true}
                  autoFocus={true}
                  agencies={currentAgencies}
                  isLoading={defaultValues.AgencyKey === ""}
                  control={control}
                  onChange={(_) => {
                    setValue("FromAgentUserKey", null, {
                      shouldValidate: true,
                    });
                    setValue("ToAgentUserKey", null, {
                      shouldValidate: true,
                    });
                    dispatch(clearSelectedSourceAgentAction());
                    dispatch(clearSelectedTargetAgentAction());
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <CustomRadioGroup
                  control={control}
                  layout={{
                    labelCol: { span: 4 },
                    wrapperCol: { span: 20 },
                  }}
                  formLabel="Type"
                  name="InventoryMovementType"
                  required={true}
                  afterChange={() => {
                    dispatch(clearSelectedSourceAgentAction());
                    dispatch(clearSelectedTargetAgentAction());
                    trigger();
                  }}
                >
                  <Space direction="vertical">
                    <Radio value={types.AllocationFromOfficeToRoadAgent}>
                      {
                        movementOptions.filter(
                          (o) =>
                            o.value === types.AllocationFromOfficeToRoadAgent
                        )[0].label
                      }
                    </Radio>
                    <Radio value={types.ReturnFromRoadAgentToOffice}>
                      {
                        movementOptions.filter(
                          (o) => o.value === types.ReturnFromRoadAgentToOffice
                        )[0].label
                      }
                    </Radio>
                    <Radio value={types.AllocationFromRoadAgentToRoadAgent}>
                      {
                        movementOptions.filter(
                          (o) =>
                            o.value === types.AllocationFromRoadAgentToRoadAgent
                        )[0].label
                      }
                    </Radio>
                  </Space>
                </CustomRadioGroup>
              </Col>
              <Col span={12}>
                {(inventoryMovementType === types.ReturnFromRoadAgentToOffice ||
                  inventoryMovementType ===
                    types.AllocationFromRoadAgentToRoadAgent) && (
                  <SourceAgentSelect
                    control={control}
                    layout={{
                      labelCol: { span: 10 },
                      wrapperCol: { span: 14 },
                    }}
                    agencyKey={agencyKey}
                  />
                )}
                {(inventoryMovementType ===
                  types.AllocationFromOfficeToRoadAgent ||
                  inventoryMovementType ===
                    types.AllocationFromRoadAgentToRoadAgent) && (
                  <TargetAgentSelect
                    control={control}
                    layout={{
                      labelCol: { span: 10 },
                      wrapperCol: { span: 14 },
                    }}
                    agencyKey={agencyKey}
                  />
                )}
              </Col>
            </Row>

            <Divider style={{ marginTop: 16, marginBottom: 16 }} />

            <Row style={{ paddingTop: 16 }}>
              <Col offset={16} span={8}>
                <Row justify="end">
                  <Col>
                    <Space>
                      <Button
                        id="processButton"
                        type="primary"
                        htmlType="submit"
                        size="small"
                        loading={loading}
                        disabled={!isValid}
                      >
                        Next
                        <RightCircleOutlined />
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default AllocateInventoryStep1;
