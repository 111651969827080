import { Form, Select } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useController } from "react-hook-form";
import { useSelector } from "react-redux";
import { checkHookFormFieldPropsEquality } from "./formValidatonRules";

const AgencySelectEx = (props) => {
  const { includeSelectAll, agencies, isLoading } = props;

  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  const agenciesLoading = useSelector((state) => state.agency.loading);

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
      isLoading={isLoading}
      includeSelectAll={includeSelectAll}
      agenciesLoading={agenciesLoading}
      agencies={agencies}
    >
      {props.children}
    </Content>
  );
};

const Content = React.memo(
  (props) => {
    const {
      field,
      error,
      includeSelectAll,
      agencies,
      isLoading,
      agenciesLoading,
      displayEmptyAsLoading = true,
    } = props;

    var inputProps = { ...props };
    delete inputProps.control;
    delete inputProps.formLabel;
    delete inputProps.layout;
    delete inputProps.required;
    delete inputProps.isTouched;
    delete inputProps.isDirty;
    delete inputProps.error;
    delete inputProps.field;
    delete inputProps.children;
    delete inputProps.formStyle;
    delete inputProps.autoFocus;
    delete inputProps.agenciesLoading;
    delete inputProps.isLoading;
    delete inputProps.agencies;
    delete inputProps.includeSelectAll;
    delete inputProps.afterChange;
    delete inputProps.displayEmptyAsLoading;

    const agencyOptions = agencies
      ? agencies.map((a) => {
          return { label: a.FullName, value: a.Id };
        })
      : [];

    if (includeSelectAll && agencies && agencies.length > 1) {
      agencyOptions.unshift({
        label: `(Select all ${agencies.length} agencies)`,
        value: 0,
      });
    }

    return (
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Form.Item
          {...props.layout}
          label={props.formLabel}
          name={props.name}
          validateStatus={error != null ? "error" : ""}
          help={error != null ? error.message : null}
          required={props.required}
          autoFocus={props.autoFocus}
          style={props.formStyle}
        >
          <div>
            <Select
              {...field}
              {...inputProps}
              showSearch
              virtual={false}
              optionFilterProp="label"
              filterOption={(input, option) => {
                const label = option.label ? option.label : option.children;
                return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              onChange={(v, o) => {
                if (props.onChange != null) {
                  props.onChange(v);
                }
                field.onChange(v, o);
                if (props.afterChange != null) {
                  props.afterChange(v);
                }
              }}
              options={agencyOptions}
              loading={
                agenciesLoading ||
                isLoading ||
                (displayEmptyAsLoading && isEmpty(agencies))
              }
            ></Select>
          </div>
        </Form.Item>
      </span>
    );
  },
  (prevProps, nextProps) =>
    checkHookFormFieldPropsEquality(prevProps, nextProps) &&
    prevProps.agenciesLoading === nextProps.agenciesLoading &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.includeSelectAll === nextProps.includeSelectAll &&
    prevProps.agencies.length === nextProps.agencies.length &&
    prevProps.displayEmptyAsLoading === nextProps.displayEmptyAsLoading
);
export default AgencySelectEx;
