//@ts-nocheck
import React, { useRef, useState } from "react";

import {
  ColProps,
  DatePicker,
  DatePickerProps,
  Form,
  FormItemProps,
} from "antd";
import { FormItemLayout } from "antd/es/form/Form";
import dayjs from "dayjs";
import { Control, useController } from "react-hook-form";
import { toEndOfDay, toStartOfDay, toTZNeutralFormat } from "../../formatting";
import { checkHookFormFieldPropsEquality } from "../formValidatonRules";
import { RangePickerProps } from "antd/es/date-picker";
import { set } from "lodash";

interface CustomFormItemProps
  extends Omit<FormItemProps, "help" | "name" | "validateStatus" | "layout"> {
  /**
   * Shortcut to set labelCol and wrapperCol
   */
  layout?: {
    labelCol?: ColProps;
    wrapperCol?: ColProps;
  };
  /**
   * Form item layout (either 'horizontal' or 'vertical')
   * - This is named 'layout' in antd design documentation
   */
  layoutDirection?: FormItemLayout;
}

// Common properties between CustomDatePickerProps and CustomDatePickerRangeProps
interface CustomDatePickerBaseProps {
  /**
   * Specifies whether time selection is shown.
   * - Setting this to 'true' will force timeOfDay = 'picked'
   */
  showTime?: boolean;
  /**
   * Form.Item properties.
   */
  formItem?: CustomFormItemProps;
  /**
   * Name of the form field.
   */
  name: string;
  /**
   * Control object from react-hook-form.
   */
  control?: Control<any>;
  /**
   * Specifies whether the selected date is considered the start or end of the day.
   * - 'start': Sets the time to 00:00:00
   * - 'end': Sets the time to 23:59:59
   * - 'picked': Sets the time to selected value (default when showTime=true)
   * - Default is 'end'
   */
  timeOfDay?: "start" | "end" | "picked";
  /**
   * Callback function that is called when the date changes.
   * - The `datestring` parameter provides the selected date as a string (timezone neutral).
   * - Implement this function to handle date changes.
   */
  onChange?: (datestring: string | string[]) => void;
}

// CustomDatePickerProps extends DatePickerProps and the common base props
interface CustomDatePickerProps
  extends Omit<DatePickerProps, "value" | "onChange" | "name" | "showTime">,
    CustomDatePickerBaseProps {}

// CustomDatePickerRangeProps extends RangePickerProps and the common base props
interface CustomDatePickerRangeProps
  extends Omit<RangePickerProps, "value" | "onChange" | "name" | "showTime">,
    CustomDatePickerBaseProps {}

type RenderDatePickerProps<T extends boolean> = T extends true
  ? CustomDatePickerRangeProps
  : CustomDatePickerProps;

type BaseContentProps = {
  field: any;
  error?: any;
  isTouched?: boolean;
  isDirty?: boolean;
};

type ContentProps<T extends boolean> = RenderDatePickerProps<T> &
  BaseContentProps & {
    isRangePicker?: T;
  };

const renderDatePicker = <T extends boolean>(
  props: RenderDatePickerProps<T>,
  isRangePicker: T
) => {
  const {
    field,
    fieldState: { error, isTouched, isDirty },
  } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Content
      {...props}
      field={field}
      error={error}
      isTouched={isTouched}
      isDirty={isDirty}
      isRangePicker={isRangePicker}
    />
  );
};

const CustomDatePicker: React.FC<CustomDatePickerProps> & {
  RangePicker: React.FC<CustomDatePickerRangeProps>;
} = (props) => {
  return renderDatePicker(props, false); // isRangePicker is false for CustomDatePicker
};

CustomDatePicker.RangePicker = (props: CustomDatePickerRangeProps) => {
  return renderDatePicker(props, true); // isRangePicker is true for CustomDatePicker.RangePicker
};

const Content = React.memo(<T extends boolean>(props: ContentProps<T>) => {
  const {
    name,
    field,
    error,
    formItem,
    timeOfDay,
    showTime,
    onChange,
    isRangePicker,
  } = props;

  const pickerRef = useRef(null);

  var inputProps = { ...props };
  delete inputProps.control;
  delete inputProps.isTouched;
  delete inputProps.isDirty;
  delete inputProps.error;
  delete inputProps.field;
  delete inputProps.timeOfDay;
  delete inputProps.formItem;

  return (
    <Form.Item
      {...((formItem ?? {}) as object)}
      {...((formItem?.layout as object) || {})}
      layout={formItem?.layoutDirection}
      name={name}
      hasFeedback={formItem?.hasFeedback}
      validateStatus={
        formItem?.showWarning === true
          ? "warning"
          : error != null
          ? "error"
          : ""
      }
      help={
        formItem?.showWarning === true && formItem?.warning
          ? formItem?.warning
          : error != null
          ? error.message
          : null
      }
    >
      <div>
        {isRangePicker === true ? (
          <DatePicker.RangePicker
            {...inputProps}
            ref={field.ref}
            value={[
              field?.value?.[0] ? dayjs(field.value[0]) : "",
              field?.value?.[1] ? dayjs(field.value[1]) : "",
            ]}
            onChange={(dates) => {
              var transformedDates = [
                toStartOfDay(dates?.[0] ?? ""),
                toEndOfDay(dates?.[1] ?? ""),
              ];
              field.onChange(transformedDates);
              onChange && onChange(transformedDates);
            }}
          />
        ) : (
          <DatePicker
            {...field}
            {...inputProps}
            ref={(e) => {
              field.ref(e);
              pickerRef.current = e;
            }}
            value={field.value ? dayjs(field.value) : ""}
            onChange={(date) => {
              var datestring = toEndOfDay(date ?? "");
              if (showTime === true || timeOfDay === "picked") {
                datestring = toTZNeutralFormat(date ?? "");
              } else if (timeOfDay === "start") {
                datestring = toStartOfDay(date ?? "");
              }

              datestring = !dayjs(datestring).isValid() ? null : datestring;
              field.onChange(datestring);
              onChange && onChange(datestring);
              pickerRef?.current?.focus();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            onBlur={(e) => {
              if (
                inputProps.allowClear &&
                e?.target?.tagName?.toString().toLowerCase() === "input"
              ) {
                var t: any = e.target;
                var value = t.defaultValue ?? "";
                if (!dayjs(value).isValid()) {
                  field.onChange(null);
                  onChange && onChange(null);
                }
              }
              props.onBlur && props.onBlur(e);
              e.preventDefault();
            }}
          />
        )}
      </div>
    </Form.Item>
  );
}, checkHookFormFieldPropsEquality);

export default CustomDatePicker;
