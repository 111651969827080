import dayjs from "dayjs";
import { customGet, customPost } from "../../api/customApi";
import entityType from "../../api/entityType";
import { Actions } from "./loanCalculatorConstants";

export const transformPaymentDetailsInput =
  ({ header, data }) =>
  (state) => {
    var BranchName =
      state.loanCalculator.agencies?.data?.find(
        (v) => header.ProcessingBranch == v.Id
      )?.AgencyName ?? "";

    return {
      ...header,
      ...data,
      BranchName: BranchName,
      FileName: "PaymentTable",
    };
  };

export const transformPOPAuthFormInput =
  ({ header, data, values }) =>
  (state) => {
    var agency = state.loanCalculator.agencies?.data?.find(
      (v) => header.ProcessingBranch == v.Id
    );

    return {
      ...header,
      ...data,
      ...values,
      BranchName: agency?.AgencyName ?? "",
      BranchPhoneNumber: agency?.PhoneNumber
        ? agency.PhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
        : "",
      BranchLocation:
        (agency?.Address ?? "") +
        ", " +
        (agency?.City ?? "") +
        ", " +
        (agency?.Province ?? "") +
        " " +
        (agency?.PostalCode ?? ""),
      InsuranceType: values.InsuranceType ?? "",
      Province: values.Province ?? "",
      Email: values.Email?.trim() || "",
      FirstPaymentDay: header?.FirstPaymentDate
        ? dayjs(header.FirstPaymentDate).date()
        : 0,
      FileName: "POPAuthForm",
    };
  };

export const clearLoanCalculator = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_LOAN_CALCULATOR,
  });
};

export const clearLoanCalculatorOperationResult = () => async (dispatch) => {
  dispatch({
    type: Actions.CLEAR_LOAN_CALCULATOR_OPERATION_RESULT,
  });
};

export const listLoanCalculatorAgencies = () => async (dispatch) => {
  dispatch({ type: Actions.LIST_LOAN_CALCULATOR_AGENCIES });

  const payload = await customGet(
    entityType.LOAN_CALCULATOR,
    "ListAgencies",
    {}
  );

  dispatch({ type: Actions.LIST_LOAN_CALCULATOR_AGENCIES_SUCCEEDED, payload });
};

export const getLoanCalculatorPaymentDetails = (values) => async (dispatch) => {
  dispatch({ type: Actions.GET_LOAN_PAYMENT_DETAILS });

  const data = await customPost(
    entityType.LOAN_CALCULATOR,
    "GetPaymentDetails",
    values,
    {}
  );

  var payload = {
    Operation: data.Operation,
    Data: { data: data.Data, header: data.Data ? values : null },
  };

  dispatch({
    type: Actions.GET_LOAN_PAYMENT_DETAILS_SUCCEEDED,
    payload: payload,
  });
};
