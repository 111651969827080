import { useOidcUser } from "@axa-fr/react-oidc";
import { Button, Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { logCustomMessage } from "../../../store/ui/uiActions";
import LayoutUserLoggedOut from "../LayoutUserLoggedOut";
import logo from "../alplus-logo.png";

export const ServiceWorkerNotSupportedComponent = () => {
  //
  const dispatch = useDispatch();
  const location = useLocation();
  const { oidcUser } = useOidcUser();

  //
  useEffect(() => {
    dispatch(
      logCustomMessage(
        "LogBrowserNotSupported",
        oidcUser?.sub,
        "Browser not supported",
        `hasUserPressedLogout: ${hasPressedLogout}`
      )
    );
  }, [oidcUser]);

  return (
    <LayoutUserLoggedOut>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={4} offset={10}>
              <div className="login-image-sizing">
                <img className="logoImageLoading" src={logo} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="login-sub-message">
              The preferred auto insurance management software by brokers in
              British Columbia
            </Col>
          </Row>
          <Row style={{ marginTop: 14 }}>
            <Col span={24} className="centered-box">
              <div className="tab-box-parent fixed-sized-box">
                <div className="tab-box-child">
                  <div className="tab-box-container">Error</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 36 }}>
            <Col span={24} className="centered-box">
              <div className="fixed-sized-box instructions-message centered-message">
                Something went wrong with your browser.
                <br />
                If this persists, please reach out to us at:
                <br /> support@brokerlinksofware.com
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 36 }}>
            <Col span={24} className="centered-box">
              <Button
                type="primary"
                className="fixed-sized-box login-button"
                onClick={() => {
                  var redirectPathname =
                    location?.pathname === "/home" ||
                    location?.pathname === "/home/"
                      ? "/"
                      : "/home";

                  dispatch({ type: "CLEAR_REDUX_STORE" });
                  window.location.replace(
                    `${process.env.REACT_APP_URI}${redirectPathname}`
                  );
                }}
              >
                Log Back In
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </LayoutUserLoggedOut>
  );
};
