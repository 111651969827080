import { Col, Row } from "antd";
import { isEmpty, isNull } from "lodash";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { shortInputStyle } from "../../../shared/form/formInputStyles";
import CustomInput from "../../../shared/form/reactHookForm/CustomInput";
import CustomInputNumber from "../../../shared/form/reactHookForm/CustomInputNumber";
import CustomRadioGroup from "../../../shared/form/reactHookForm/CustomRadioGroup";

const specialLayout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

export const defaultLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const PolicyCustomerPanel = ({ isNew = false, control }) => {
  // const { control } = useFormContext();
  const selected = useSelector((state) => state.document.selected);
  const maskSensitiveData =
    useSelector((state) => state.actor?.details?.data?.MaskSensitiveData) &&
    !isNew;
  const isDownloaded = selected && selected.IsDownloaded;
  const isUserAllowedToSave = !isNull(selected)
    ? selected.IsUserAllowedToSave
    : 1;
  const isBatched =
    selected &&
    !(isEmpty(selected.AgencyBatchNo) || isNull(selected.AgencyBatchNo));

  return (
    <>
      <Row>
        <Col span={8}>
          <CustomInput
            layout={defaultLayout}
            formLabel="Customer Name 1"
            required={true}
            control={control}
            name="CustomerName1"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Customer Name 2"
            control={control}
            name="CustomerName2"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomRadioGroup
            control={control}
            layout={defaultLayout}
            formLabel="Lease"
            name="LeaseIndicator"
            disabled={!isUserAllowedToSave}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
          ></CustomRadioGroup>

          <CustomInput
            layout={defaultLayout}
            formLabel="Lessor"
            control={control}
            name="LessorName"
            maxLength={60}
            disabled={selected && (isDownloaded || !isUserAllowedToSave)}
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Principal Driver"
            control={control}
            name="PrincipleOperatorName"
            maxLength={27}
            disabled={selected && (isDownloaded || !isUserAllowedToSave)}
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Driver's License"
            control={control}
            name="DriversLicense"
            maxLength={8}
            style={shortInputStyle}
            disabled={!isUserAllowedToSave}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Principal DL"
            control={control}
            name="PrincipalDriversLicense"
            maxLength={8}
            style={shortInputStyle}
            disabled={selected && (isDownloaded || !isUserAllowedToSave)}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Lessee DL"
            control={control}
            name="LesseeDriversLicense"
            maxLength={8}
            style={shortInputStyle}
            disabled={selected && (isDownloaded || !isUserAllowedToSave)}
          />
        </Col>
        <Col span={8}>
          <CustomRadioGroup
            control={control}
            layout={specialLayout}
            formLabel="Renewal Consent"
            name="CustomerConsentYN"
            disabled={
              (selected &&
                !isNew &&
                (isNull(selected.PolicyExpiryDate) ||
                  isEmpty(selected.PolicyExpiryDate))) ||
              !isUserAllowedToSave
            }
            options={[
              { label: "Yes", value: "Y" },
              { label: "No", value: "N" },
              { label: "Blank", value: "" },
            ]}
          ></CustomRadioGroup>

          <CustomInput
            layout={specialLayout}
            formLabel="Primary Phone"
            control={control}
            name="CustomerHomePhone"
            //maxLength={3} tbd
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={specialLayout}
            formLabel="Email"
            control={control}
            name="CustomerEmail"
            maxLength={60}
            disabled={!isUserAllowedToSave}
            className={maskSensitiveData === true ? "blurry-text" : ""}
            autoComplete="off"
          />

          <AdditionalCustomerControls
            isNew={isNew}
            selected={selected}
            isDownloaded={isDownloaded}
            isUserAllowedToSave={isUserAllowedToSave}
            isBatched={isBatched}
          />
        </Col>
        <Col span={8}>
          <CustomInput
            layout={defaultLayout}
            formLabel="Address 1"
            control={control}
            name="CustomerAddr1"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Address 2"
            control={control}
            name="CustomerAddr2"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Address 3"
            control={control}
            name="CustomerAddr3"
            maxLength={27}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
            className={maskSensitiveData === true ? "blurry-text" : ""}
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="City"
            control={control}
            maxLength={27}
            name="City"
            disabled={!isUserAllowedToSave}
            autoComplete="off"
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Province"
            control={control}
            name="ProvinceCode"
            maxLength={2}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
          />

          <CustomInput
            layout={defaultLayout}
            formLabel="Postal Code"
            control={control}
            name="PostalCode"
            maxLength={6}
            disabled={!isUserAllowedToSave}
            autoComplete="off"
          />
        </Col>
      </Row>
    </>
  );
};

export default React.memo(PolicyCustomerPanel);

const AdditionalCustomerControls = ({
  isNew,
  selected,
  isDownloaded,
  isUserAllowedToSave,
  isBatched,
}) => {
  const { control, watch } = useFormContext();
  const FleetNumber = watch("FleetNumber");

  return (
    <AdditionalCustomerControlsMemoized
      isNew={isNew}
      selected={selected}
      isDownloaded={isDownloaded}
      isUserAllowedToSave={isUserAllowedToSave}
      isBatched={isBatched}
      FleetNumber={FleetNumber}
      control={control}
    />
  );
};

const AdditionalCustomerControlsMemoized = React.memo(
  ({
    isNew,
    selected,
    isDownloaded,
    isUserAllowedToSave,
    isBatched,
    FleetNumber,
    control,
  }) => {
    return (
      <>
        <CustomInputNumber
          layout={specialLayout}
          formLabel="CDF"
          control={control}
          style={shortInputStyle}
          name="CDF"
          disabled={
            selected &&
            (isNull(selected.CRSLevel) ||
              !isEmpty(FleetNumber) ||
              isDownloaded ||
              !isUserAllowedToSave)
          }
        />
        {!isNew && (
          <CustomInput
            layout={specialLayout}
            formLabel="CRS Level"
            control={control}
            name="CRSLevel"
            disabled={true}
            style={shortInputStyle}
          />
        )}

        <CustomRadioGroup
          control={control}
          layout={specialLayout}
          formLabel="Disability Discount"
          name="DisabilityDiscIndicator"
          disabled={
            selected &&
            (isDownloaded || !isUserAllowedToSave || !isEmpty(FleetNumber))
          }
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        ></CustomRadioGroup>

        <CustomInput
          layout={specialLayout}
          formLabel="Fleet"
          control={control}
          name="FleetNumber"
          disabled={!isUserAllowedToSave}
          style={shortInputStyle}
          maxLength={6}
        />
        <CustomInput
          layout={specialLayout}
          formLabel="Fleet Unit"
          control={control}
          name="FleetUnitNumber"
          disabled={!isUserAllowedToSave}
          maxLength={5}
          style={shortInputStyle}
        />

        <CustomRadioGroup
          control={control}
          layout={specialLayout}
          formLabel="Batch as Multi-Licence"
          name="FleetBatchIndicator"
          disabled={isBatched || !isUserAllowedToSave}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        ></CustomRadioGroup>

        <CustomInputNumber
          layout={specialLayout}
          formLabel="Fleet Discount / Surcharge %"
          control={control}
          style={shortInputStyle}
          name="FleetDiscountSurcharge"
          formTooltip={
            "Surcharge is a positive number, Discount is a negative number."
          }
          disabled={
            isDownloaded || !isUserAllowedToSave || isEmpty(FleetNumber)
          }
        />
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.FleetNumber === nextProps.FleetNumber &&
    prevProps.isNew === nextProps.isNew &&
    prevProps.isDownloaded === nextProps.isDownloaded &&
    prevProps.isUserAllowedToSave === nextProps.isUserAllowedToSave &&
    prevProps.isBatched === nextProps.isBatched &&
    ((prevProps.selected == null && nextProps.selected == null) ||
      (prevProps.selected != null &&
        nextProps.selected != null &&
        prevProps.selected.DocumentKey === nextProps.selected.DocumentKey &&
        prevProps.selected.FleetNumber === nextProps.selected.FleetNumber &&
        prevProps.selected.CRSLevel === nextProps.selected.CRSLevel))
);
