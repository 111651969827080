import {
  Button,
  Card,
  Col,
  Form,
  Popconfirm,
  Row,
  Space,
  Spin,
  Typography,
  Radio,
} from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listAgenciesCompactAction } from "../../store/agency/agencyActions";
import {
  clearOperationResultAction,
  clearSelectedUserAction,
  deleteUser,
  listUsersAction,
} from "../../store/user/general/userActions";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { AdminTasks } from "../shared/securityRoleTasks";
import { userSchema } from "./userSchema";
import { DeleteOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../shared/BackButton";
import { usePreservableState } from "../shared/PreservableStatePageWrapper";
import CustomCheckbox from "../shared/form/reactHookForm/CustomCheckbox";
import CustomInput from "../shared/form/reactHookForm/CustomInput";
import CustomSelect from "../shared/form/reactHookForm/CustomSelect";
import CustomDatePicker from "../shared/form/reactHookForm/CustomDatePicker";
import { dateFormat, formatBoolean } from "../shared/formatting";
import CustomInputTextArea from "../shared/form/reactHookForm/CustomInputTextArea";
import CustomRadioGroup from "../shared/form/reactHookForm/CustomRadioGroup";

const { Paragraph } = Typography;

// Constants
const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

const UserForm = ({
  initialValues,
  onSubmitHandler,
  isNew = false,
  agencyKeys,
}) => {
  // Form
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    watch,
    getFieldState,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(userSchema),
  });

  //
  const dispatch = useDispatch();
  const { isRestorable, restorePreviousPage } = usePreservableState();

  const location = useLocation();
  const navigate = useNavigate();

  //
  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );
  const isAdmin = useSelector((state) => state.actor.details.data.IsAdmin);
  const canAccessRestrictedFleets = useSelector(
    (state) => state.actor.details.data.CanAccessRestrictedFleets
  );
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const loading = useSelector((state) => state.user.general.loading);
  const agencies = useSelector((state) => state.agency.listCompact);
  const agenciesLoading = useSelector((state) => state.agency.loading);
  const operationResult = useSelector(
    (state) => state.user.general.operationResult
  );
  const isDeleteLoading = useSelector(
    (state) => state.user.general.isDeleteLoading
  );
  const isDeleted = useSelector((state) => state.user.general.isDeleted);

  const selected = useSelector((state) => state.user.general.selected);

  //
  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactAction(brokerId, AdminTasks));
    }
  }, [brokerId]);

  useEffect(() => {
    if (operationResult != null && operationResult.Outcome === "Success") {
      reset(selected);
    }
    if (
      !(
        operationResult != null &&
        operationResult.Outcome === "Success" &&
        isNew
      )
    ) {
      openNotificationOperationResult(operationResult, () =>
        dispatch(clearOperationResultAction())
      );
    }
  }, [operationResult]);

  useEffect(() => {
    if (isDeleted) {
      if (location.key !== "default" && isRestorable === true) {
        restorePreviousPage();
        parentRefreshHandler();
        dispatch(clearSelectedUserAction());
        navigate(-1);
      } else {
        dispatch(clearSelectedUserAction());
        navigate("/home", { replace: true });
      }
    }
  }, [isDeleted]);

  const agencyOptions = agencies
    ? agencies.map((a) => {
        return { label: a.FullName, value: a.Id };
      })
    : [];

  const parentRefreshHandler = () => dispatch(listUsersAction(agencyKeys));

  const onSubmitHandlerEx = (values) => {
    const { EmailConfirmed, PasswordSet, ...transformedAccount } =
      values.Account || {};
    onSubmitHandler({
      Account: transformedAccount,
      Details: {
        ...values.Details,
        BrokerId: brokerId,
      },
    });
  };

  //
  const accessEnabled = watch("Details.AccessEnabled");

  return (
    <Spin spinning={isDeleteLoading} delay={500}>
      <Form autoComplete="off" size="small">
        <Row style={{ paddingBottom: 4 }}>
          <Col span={14}>
            <Row justify="space-between">
              <Col>
                <Space>
                  <BackButton
                    onGoBackHandler={() => {
                      parentRefreshHandler();
                      dispatch(clearSelectedUserAction());
                    }}
                    isDirty={isDirty}
                  />
                  {getFieldState("Account.Email").isDirty &&
                  !getFieldState("Details.AccessEnabled").isDirty &&
                  getValues().Details.AccessEnabled &&
                  !isNew ? (
                    <Popconfirm
                      title={
                        <>
                          <Paragraph>
                            Changing the email address will result in a reset of
                            the account holder's credentials.
                          </Paragraph>
                          Proceed with this change?
                        </>
                      }
                      onConfirm={() => handleSubmit(onSubmitHandlerEx)()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="primary"
                        loading={loading}
                        id="processButton"
                        htmlType="submit"
                        disabled={!isValid || !isDirty || isDeleteLoading}
                      >
                        <SaveOutlined />
                        Save
                      </Button>
                    </Popconfirm>
                  ) : (
                    <Button
                      onClick={() => handleSubmit(onSubmitHandlerEx)()}
                      type="primary"
                      loading={loading}
                      id="processButton"
                      htmlType="submit"
                      disabled={!isValid || !isDirty || isDeleteLoading}
                    >
                      <SaveOutlined />
                      Save
                    </Button>
                  )}
                  <Button
                    size="small"
                    type="dashed"
                    disabled={!isDirty || loading || isDeleteLoading}
                    onClick={() => {
                      reset();
                      trigger();
                    }}
                  >
                    <UndoOutlined />
                    Reset Values
                  </Button>
                </Space>
              </Col>
              <Col>
                {!isNew && (
                  <Popconfirm
                    title="Delete account"
                    description={
                      <>
                        <Paragraph>
                          You are about to delete the account for{" "}
                          {selected?.Account?.FirstName ?? ""}{" "}
                          {selected?.Account?.LastName ?? ""}.
                          <br />
                          This action cannot be undone.
                          <br />
                          Would you like to proceed?
                        </Paragraph>
                      </>
                    }
                    onCancel={() => {
                      dispatch(
                        deleteUser({
                          UserKeyToDelete: selected.Details.UserKey,
                        })
                      );
                    }}
                    cancelText="Yes, Delete"
                    okText="No, Cancel Delete"
                  >
                    <Button
                      size="small"
                      type="default"
                      danger={true}
                      disabled={loading}
                      loading={isDeleteLoading}
                      onClick={() => {}}
                    >
                      <DeleteOutlined />
                      Delete
                    </Button>
                  </Popconfirm>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ paddingBottom: 16 }}>
          <Col span={14}>
            <Card
              title="Account Information"
              size="small"
              style={{ height: 600 }}
            >
              <CustomInput
                control={control}
                layout={layout}
                required={true}
                formLabel="First Name"
                name="Details.NameFirst"
                autoComplete="off"
                className={
                  !isNew && maskSensitiveData === true ? "blurry-text" : ""
                }
              />

              <CustomInput
                control={control}
                layout={layout}
                required={true}
                formLabel="Last Name"
                autoComplete="off"
                name="Details.NameLast"
                className={
                  !isNew && maskSensitiveData === true ? "blurry-text" : ""
                }
              />

              <CustomSelect
                formLabel="Home Agency"
                layout={layout}
                control={control}
                name="Details.AgencyKey"
                options={agencyOptions}
                required={true}
                loading={agenciesLoading}
                showSearch={true}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.label
                    .toLowerCase()
                    .localeCompare(optionB.label.toLowerCase())
                }
              />
              <CustomDatePicker
                formItem={{
                  layout: layout,
                  label: "Employment Start Date",
                }}
                control={control}
                name="Details.EmploymentStartDate"
                format={dateFormat}
                allowClear={true}
                timeOfDay="start"
              />
              <CustomDatePicker
                formItem={{
                  layout: layout,
                  label: "Employment End Date",
                }}
                control={control}
                name="Details.EmploymentEndDate"
                format={dateFormat}
                allowClear={true}
                timeOfDay="start"
              />
              <CustomInputTextArea
                control={control}
                layout={layout}
                formLabel="Notes"
                style={{ resize: "none", height: 70 }}
                name="Details.Comment"
              />
              <CustomRadioGroup
                control={control}
                formLabel="Enable Autolink Access"
                name="Details.AccessEnabled"
                layout={layout}
                required={true}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </CustomRadioGroup>

              {accessEnabled && (
                <CustomInput
                  control={control}
                  formStyle={
                    canAccessRestrictedFleets
                      ? { marginBottom: 0 }
                      : { marginBottom: 24 }
                  }
                  layout={layout}
                  required={true}
                  formLabel="Email"
                  name="Account.Email"
                  autoComplete="off"
                  className={
                    !isNew && maskSensitiveData === true ? "blurry-text" : ""
                  }
                />
              )}
              {accessEnabled && !isNew && (
                <>
                  {canAccessRestrictedFleets && (
                    <CustomRadioGroup
                      control={control}
                      formLabel="Allow Access to Restricted Fleets"
                      name="Details.CanAccessRestrictedFleets"
                      layout={layout}
                      required={true}
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </CustomRadioGroup>
                  )}
                  <Form.Item
                    label="Email Confirmed"
                    name="Account.EmailConfirmed"
                    {...layout}
                  >
                    {formatBoolean(initialValues?.Account.EmailConfirmed)}
                  </Form.Item>
                  <Form.Item
                    label="Password Set"
                    name="Account.PasswordSet"
                    {...layout}
                  >
                    {formatBoolean(initialValues?.Account.PasswordSet)}
                  </Form.Item>
                </>
              )}
            </Card>
          </Col>
          <Col span={10} style={{ paddingLeft: 16 }}>
            <Card
              title="Additional Settings"
              size="small"
              style={{ height: 600 }}
            >
              <CustomCheckbox
                control={control}
                formLabel=""
                formStyle={{ marginBottom: 0, paddingLeft: 24 }}
                name="Details.IsAllocatedInventory"
                onChange={(e) => {
                  if (!e.target.checked) {
                    setValue(
                      "Details.CanBeAllocatedInventoryFromOtherAgencies",
                      false,
                      { shouldValidate: true, shouldDirty: true }
                    );
                  }
                  trigger();
                }}
              >
                Road Agent
              </CustomCheckbox>

              <CustomCheckbox
                control={control}
                formLabel=""
                formStyle={{ paddingLeft: 48 }}
                name="Details.CanBeAllocatedInventoryFromOtherAgencies"
                disabled={
                  getValues().Details &&
                  !getValues().Details.IsAllocatedInventory
                }
              >
                Road Agent For All Branches
              </CustomCheckbox>

              <CustomCheckbox
                control={control}
                formLabel=""
                formStyle={{ marginBottom: 0, paddingLeft: 24 }}
                name="Details.IsPaidCommission"
                onChange={(e) => {
                  if (!e.target.checked) {
                    setValue("Details.IsPaidBasicComm", false, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    setValue("Details.IsPaidCommForReceipts", false, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    setValue("Details.IsPaidCommForMvb", false, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    setValue("Details.IsPaidCommForApchange", false, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }
                  trigger();
                }}
              >
                Commissionable Agent
              </CustomCheckbox>

              <CustomCheckbox
                control={control}
                formLabel=""
                formStyle={{ marginBottom: 0, paddingLeft: 48 }}
                name="Details.IsPaidBasicComm"
                disabled={
                  getValues().Details && !getValues().Details.IsPaidCommission
                }
              >
                Paid Basic Commission
              </CustomCheckbox>

              <CustomCheckbox
                control={control}
                formLabel=""
                formStyle={{ marginBottom: 0, paddingLeft: 48 }}
                name="Details.IsPaidCommForReceipts"
                disabled={
                  getValues().Details && !getValues().Details.IsPaidCommission
                }
              >
                Paid for Debt Collection
              </CustomCheckbox>

              <CustomCheckbox
                control={control}
                formLabel=""
                formStyle={{ marginBottom: 0, paddingLeft: 48 }}
                name="Details.IsPaidCommForMvb"
                disabled={
                  getValues().Details && !getValues().Details.IsPaidCommission
                }
              >
                Paid for Complex Transactions (aka MVB)
              </CustomCheckbox>

              <CustomCheckbox
                control={control}
                formLabel=""
                formStyle={{ paddingLeft: 48 }}
                name="Details.IsPaidCommForApchange"
                disabled={
                  getValues().Details && !getValues().Details.IsPaidCommission
                }
              >
                Paid for Payment Plan Transactions
              </CustomCheckbox>

              {isAdmin && (
                <CustomCheckbox
                  control={control}
                  layout={layout}
                  formStyle={{ paddingLeft: 24 }}
                  name="Details.MaskSensitiveData"
                >
                  Mask Sensitive Data
                </CustomCheckbox>
              )}
            </Card>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default UserForm;
