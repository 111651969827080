import { PlusOutlined, FileExcelOutlined } from "@ant-design/icons";
import {
  Button,
  Radio,
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Form,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { SearchableTable } from "../shared/table/SearchableTable";
import openNotificationOperationResult from "../shared/openNotificationOperationResult";
import { tableNames } from "../shared/table/tableNames";
import {
  PreservableStatePageWrapper,
  usePreservableNavigate,
} from "../shared/PreservableStatePageWrapper";
import PermissionWrapper from "../navigation/PermissionWrapper";
import getProducerCodeColumns from "./getProducerCodeColumns";
import {
  listProducerCodes,
  clearOperationResultAction,
  setSelectedCodeNumber,
} from "../../store/producer/producerActions";
import UpdateProducerCode from "./UpdateProducerCode";
import CreateProducerCode from "./CreateProducerCode";
import { useForm } from "react-hook-form";
import AgencyMultiSelectEx from "../shared/form/AgencyMultiSelectEx";
import { isEmpty } from "lodash";
import {
  listAgenciesCompactForProducerCodes,
  clearListCompactAction,
} from "../../store/agency/agencyActions";
import { AdminTasks } from "../shared/securityRoleTasks";
import { yupResolver } from "@hookform/resolvers/yup";
import { getInitialAgency } from "../shared/form/getInitialAgency";
import { agenciesListEqual } from "../shared/sorting";
import CustomRadioGroup from "../shared/form/reactHookForm/CustomRadioGroup";
import { codesSchema } from "./producerCodeSchema";
import { exportDataToExcel } from "../shared/exportToExcel";

const layout = {
  labelCol: {
    xs: { span: 5 },
    sm: { span: 5 },
    md: { span: 5 },
    lg: { span: 8 },
    xl: { span: 6 },
    xxl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 19 },
    sm: { span: 19 },
    md: { span: 19 },
    lg: { span: 16 },
    xl: { span: 18 },
    xxl: { span: 19 },
  },
};

const Producers = () => {
  const dispatch = useDispatch();

  return (
    <PreservableStatePageWrapper
      onCleanup={() => {
        dispatch(setSelectedCodeNumber(1));
      }}
      tableNames={[tableNames.Producers]}
    >
      <ProducersBody />
    </PreservableStatePageWrapper>
  );
};

const ProducersBody = () => {
  const agencies = useSelector((state) => state.agency.listCompact);
  const homeAgency = useSelector((state) => state.actor.details.data.AgencyKey);
  const data = useSelector((state) => state.producer.data);
  const loading = useSelector((state) => state.producer.loading);
  const operationResult = useSelector(
    (state) => state.producer.operationResult
  );
  const brokerId = useSelector((state) => state.actor.details.data.BrokerId);
  const dispatch = useDispatch();
  const preservableNavigate = usePreservableNavigate();

  useEffect(() => {
    openNotificationOperationResult(operationResult, () =>
      dispatch(clearOperationResultAction())
    );
  }, [operationResult]);

  const {
    reset,
    control,
    setValue,
    getValues,
    trigger,
    formState: { isValid, defaultValues },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(codesSchema),
  });

  useEffect(() => {
    if (brokerId != null) {
      dispatch(listAgenciesCompactForProducerCodes(brokerId, AdminTasks));
    }
  }, [brokerId]);

  useEffect(() => {
    return () => {
      // Clear the list of all agencies
      dispatch(clearListCompactAction());
    };
  }, []);

  const [currentAgencies, setCurrentAgencies] = useState([]);

  useEffect(() => {
    if (
      agencies != null &&
      !isEmpty(agencies) &&
      !agenciesListEqual(currentAgencies, agencies)
    ) {
      setCurrentAgencies(agencies);

      var initialAgency = getInitialAgency(homeAgency, agencies);
      const initialValues = {
        AgencyKeys: [initialAgency],
        CodeNumber: 1,
      };

      initialAgency && dispatch(listProducerCodes(initialValues));

      reset(initialValues);

      trigger();
    }
  }, [agencies, homeAgency]);

  const tableData = useSelector((state) =>
    state.ui.tables.find((b) => b.name === tableNames.Producers)
  );

  const maskSensitiveData = useSelector(
    (state) => state.actor?.details?.data?.MaskSensitiveData
  );

  const [isExporting, setIsExporting] = useState(false);

  const exportTableData = () => {
    setIsExporting(true);

    const columnsToRemove = currentAgencies.some(
      (a) => a.EnableEPICCodesForProducers
    )
      ? [
          "Id",
          "ProducerCodeKey",
          "UserKey",
          "AgencyKey",
          "AgencyName",
          "AgencyNumber",
          "CompanyKey",
          "DateModified",
          "UserModified",
        ]
      : [
          "Id",
          "ProducerCodeKey",
          "UserKey",
          "AgencyKey",
          "AgencyName",
          "AgencyNumber",
          "CompanyKey",
          "DateModified",
          "UserModified",
          "EPICCode",
        ];

    exportDataToExcel(
      tableData?.extra.currentDataSource,
      columnsToRemove,
      maskSensitiveData,
      "ProducerCodes.csv",
      ["IsLinkedToUser", "AppliesInAllAgencies", "UserAccountIsActive"]
    );
    setIsExporting(false);
  };

  const addButton = (
    <Button
      size={"small"}
      type="primary"
      onClick={() => {
        preservableNavigate(`createproducercode`);
      }}
    >
      <PlusOutlined />
      Add New
    </Button>
  );

  const exportButton = (
    <Button
      size={"small"}
      loading={isExporting}
      onClick={() => exportTableData()}
      disabled={isEmpty(data)}
    >
      <FileExcelOutlined />
      Export
    </Button>
  );

  const toolbarButtons = [addButton, exportButton];

  return (
    <PermissionWrapper entityName="Producers">
      <Routes>
        <Route
          path=""
          element={
            <>
              <Row>
                <Col span={24}>
                  <Form size="small" autoComplete="off">
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                        <AgencyMultiSelectEx
                          layout={layout}
                          formLabel="Agency"
                          agencies={currentAgencies}
                          isLoading={isEmpty(agencies)}
                          setValue={setValue}
                          control={control}
                          name="AgencyKeys"
                          required={true}
                          onChange={(newAgencyKeys) =>
                            dispatch(
                              listProducerCodes({
                                ...getValues(),
                                AgencyKeys: newAgencyKeys,
                              })
                            )
                          }
                        />
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                        <CustomRadioGroup
                          control={control}
                          layout={layout}
                          formLabel="Manage"
                          name="CodeNumber"
                          required={true}
                          autoFocus={true}
                          onChange={(value) => {
                            isValid &&
                              dispatch(
                                listProducerCodes({
                                  ...getValues(),
                                  CodeNumber: value,
                                })
                              );
                          }}
                          afterChange={() =>
                            dispatch(listProducerCodes(getValues()))
                          }
                        >
                          <Radio value={1}>Producer Code 1</Radio>
                          <Radio value={2}>Producer Code 2</Radio>
                          <Radio value={0}>All</Radio>
                        </CustomRadioGroup>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Divider style={{ marginTop: 0 }} />
              <Row>
                <Col span={24}>
                  <SearchableTable
                    dataSource={data}
                    columns={getProducerCodeColumns(getValues())}
                    loading={loading}
                    buttons={toolbarButtons}
                    rowKey={(r) => r.Id}
                    tableName={tableNames.Producers}
                    pageSize={100}
                    size="small"
                    scroll={{ y: 650, x: 820 }}
                  />
                </Col>
              </Row>
            </>
          }
        />

        <Route
          path="createproducercode"
          element={
            <CreateProducerCode
              parentRefreshHandler={() =>
                dispatch(listProducerCodes(getValues()))
              }
            />
          }
        />

        <Route
          path="updateproducercode/:codeKey"
          element={
            <UpdateProducerCode
              parentRefreshHandler={() =>
                dispatch(listProducerCodes(getValues()))
              }
            />
          }
        />
      </Routes>
    </PermissionWrapper>
  );
};
export default Producers;
